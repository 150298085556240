export interface IMemberResponse {
  id: number;
  fullName?: string;
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  passport?: string;
  visa?: string;
  visaExpiredDay?: Date;
  occupation?: string;
  company?: string;
  temporaryRegistrationDate?: Date;
  temporaryRegistrationEnd?: Date;
  temporaryRegistrationNumber?: string;
  phoneCode?: string;
  gender?: string;
  birthday?: Date;
  nationality?: string;
  identifier?: string;
  idDate?: Date;
  idPlace?: string;
  taxCode?: string;
  fromDate?: Date;
  toDate?: Date;
  avatar?: string;
  relationship?: string;
  addedToFamilyRegisterDay?: Date;
  role?: string;
  address?: string;
  phoneNotUseApp?: string;
  phoneNotUseAppCode?: string;
  backupEmail?: string;
}

export interface IMemberModel {
  id: number;
  fullName?: string;
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  passport?: string;
  visa?: string;
  visaExpiredDay?: Date;
  occupation?: string;
  company?: string;
  temporaryRegistrationDate?: Date;
  temporaryRegistrationEnd?: Date;
  temporaryRegistrationNumber?: string;
  phoneCode?: string;
  gender?: string;
  birthday?: Date;
  nationality?: string;
  identifier?: string;
  idDate?: Date;
  idPlace?: string;
  taxCode?: string;
  fromDate?: Date;
  toDate?: Date;
  avatar?: string;
  relationship?: string;
  addedToFamilyRegisterDay?: Date;
  role?: string;
  address?: string;
  phoneNotUseApp?: string;
  phoneNotUseAppCode?: string;
  backupEmail?: string;
}

const MemberModel = (data?: IMemberResponse): IMemberModel => ({
  id: data?.id ?? 0,
  fullName: data?.fullName ?? '',
  phone: data?.phone ?? '',
  role: data?.role,
  email: data?.email ?? '',
  backupEmail: data?.backupEmail ?? '',
  phoneNotUseApp: data?.phoneNotUseApp ?? '',
});

export default MemberModel;
