import moment from 'moment';
import { convertArrToObj } from 'shared/utils';
import { language } from 'shared/language';
import { OTHER } from './gender';

export const RENT_STATUS_ACTIVE = 'ACTIVE';
export const RENT_STATUS_INACTIVE = 'INACTIVE';
export const RENT_STATUS_LIQUIDATED = 'LIQUIDATED';
export const RENT_STATUS_EXPIRED = 'EXPIRED';
export const RENT_STATUS_CANCELLED = 'CANCELLED';
export const RENT_STATUS_DRAFT = 'DRAFT';

export const RENT_STATUS_ARR = [
  {
    id: RENT_STATUS_ACTIVE,
    label: language.active,
    color: 'processing',
  },
  {
    id: RENT_STATUS_INACTIVE,
    label: language.inactive,
    color: 'default',
  },
  {
    id: RENT_STATUS_LIQUIDATED,
    label: language.liquidated,
    color: 'success',
  },
  {
    id: RENT_STATUS_CANCELLED,
    label: language.cancelled,
    color: 'default',
  },
];

export const RENT_STATUS_OBJ = RENT_STATUS_ARR.reduce(
  (
    result: {
      [key: string]: any;
    },
    status,
  ) => {
    // eslint-disable-next-line no-param-reassign
    result[status.id] = status;
    return result;
  },
  {},
);

export const RENT_TABLE_COLUMN_KEY_ARR = ['index', 'floor', 'contractCode', 'rentUnit', 'contractTerm', 'paidTo', 'status', 'action'];

export const ACTION_BY_ADD_CONTRACT = 'ADD_CONTRACT';
export const ACTION_BY_ADD_ANNEX = 'ADD_ANNEX';

export const RENT_CONTRACT_TYPE_CONTRACT = 'CONTRACT';
export const RENT_CONTRACT_TYPE_ANNEX = 'ANNEX';
export const RENT_CONTRACT_TYPE_LIQUIDATE = 'LIQUIDATE';
export const RENT_CONTRACT_TYPE_CANCEL = 'CANCEL';

export const RENT_CONTRACT_TYPE_OBJ: {
  [key: string]: { id: string; label: string };
} = {
  [RENT_CONTRACT_TYPE_CONTRACT]: { id: RENT_CONTRACT_TYPE_CONTRACT, label: language.origin_contract },
  [RENT_CONTRACT_TYPE_ANNEX]: { id: RENT_CONTRACT_TYPE_ANNEX, label: language.annex },
  [RENT_CONTRACT_TYPE_LIQUIDATE]: { id: RENT_CONTRACT_TYPE_LIQUIDATE, label: language.liquidate },
  [RENT_CONTRACT_TYPE_CANCEL]: { id: RENT_CONTRACT_TYPE_CANCEL, label: language.cancelled },
};

export const RENT_CONTRACT_DEFAULT_FILTER = {
  status: [RENT_STATUS_ACTIVE],
  expiredDate: [],
  contractCode: undefined,
  rentUnit: undefined,
};

export const RENT_CONTRACT_DEFAULT_FORM_ADD = {
  signingAt: moment(),
  startUsageAt: moment(),
  paymentCycle: 1,
  lessorGender: OTHER,
  lesseeGender: OTHER,
  media: [],
  assets: [],
  rentalAreas: [
    {
      id: undefined,
      rentalAreaType: undefined,
      room: undefined,
      amount: undefined,
      tariff: undefined,
      floor: undefined,
      active: true,
    },
  ],
};

export const RENTAL_AREA_TYPE_ROOM = 'ROOM';
export const RENTAL_AREA_TYPE_AREA = 'AREA';
export const RENTAL_AREA_TYPE_CHAIR_FIXED = 'CHAIR_FIXED';
export const RENTAL_AREA_TYPE_CHAIR_FLEXIBLE = 'CHAIR_FLEXIBLE';
export const RENTAL_AREA_TYPES = [
  { value: RENTAL_AREA_TYPE_ROOM, label: language.room, icon: '/assets/images/door.png', order: 4 },
  { value: RENTAL_AREA_TYPE_AREA, label: language.area, icon: '/assets/images/area.png', order: 3 },
  {
    value: RENTAL_AREA_TYPE_CHAIR_FIXED,
    label: language.fixed_chair,
    icon: '/assets/images/fixed-chair.png',
    order: 1,
  },
  {
    value: RENTAL_AREA_TYPE_CHAIR_FLEXIBLE,
    label: language.flexible_chair,
    icon: '/assets/images/flexible-chair.png',
    order: 2,
  },
];

export const RENTAL_AREA_TYPE_OBJ = convertArrToObj(RENTAL_AREA_TYPES, 'value');

export const RENT_CONTRACT_DETAIL_MENU_KEY_CONTRACT = 'CONTRACT';
export const RENT_CONTRACT_DETAIL_MENU_KEY_DETAIL_BASIC = 'DETAIL_BASIC';

export const RENTAL_UNIT_TRANSACTION_DEFAULT_FILTER = {
  code: undefined,
  paymentMethod: '',
  paymentAt: [moment(), moment()],
};
