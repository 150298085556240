export interface IUnitGroupResponse {
  imageCover?: string;
  name?: string;
  id: number;
  unitGroupConfigs: any;
}

export interface IUnitGroupModel {
  imageCover?: string;
  name?: string;
  id: number;
  unitGroupConfigs: any;
}

const UnitGroupModel = (data?: IUnitGroupResponse): IUnitGroupModel => {
  return {
    id: data?.id ?? 0,
    name: data?.name ?? '',
    imageCover: data?.imageCover ?? '',
    unitGroupConfigs: data?.unitGroupConfigs?.length ? data?.unitGroupConfigs[0] : [],
  };
};

export default UnitGroupModel;
