import moment from 'moment';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import uniqBy from 'lodash/uniqBy';
import pickBy from 'lodash/pickBy';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import { language } from '../language';
import threeDigits from './threeDigits';
import readFileExcel from './readFileExcel';

export const validateEmail = (email: string): boolean => {
  const newLocal = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
  // eslint-disable-next-line no-control-regex
  const pattern = newLocal;
  return pattern.test(email);
};

export const validateUrl = (url: string): boolean => {
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
  const pattern = new RegExp(regex, 'g');
  return pattern.test(url);
};

export const validatePhone = (phone: string): boolean => {
  const pattern = /^[1-9](\d{3,10})$/g;
  return pattern.test(phone);
};

export const validatePhoneV2 = (phone: string): boolean => {
  const pattern = /^[1-9](\d{7,10})$/g;
  return pattern.test(phone);
};

export const validatePhoneV3 = (phone: string): boolean => {
  const pattern = /^[0-9](\d{7,10})$/g;
  return pattern.test(phone);
};

export const validatePhoneInFile = (phone: string, phoneCode: string = '+84'): boolean => {
  const regex = `^(0|\\${phoneCode})(\\d{8,10})$`;
  const pattern = new RegExp(regex, 'g');
  return pattern.test(phone);
};

export const validateSearchAge = (age: string): boolean => {
  const pattern = /^\d{1,4}-\d{1,4}$/g;
  return pattern.test(age);
};

export const validateText = (value: string): boolean => {
  const pattern = /<[a-z | A-Z][\s\S]*>/g;
  return pattern.test(value);
};

export const validateMoney = (value: string): boolean => {
  const pattern = /(?=.*\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$/;
  return pattern.test(value);
};

export const validateDecimalNumber = (value: string): boolean => {
  const pattern = /^\d+(\.\d{1,4})?$/;
  return pattern.test(value);
};

export function validateNumber({
  value,
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  isInteger,
}: {
  value: string;
  min: number;
  max: number;
  isInteger?: boolean;
}): string {
  if (isInteger) {
    const pattern = /^\d*$/g;
    if (!pattern.test(value)) {
      return language.value_is_integer;
    }
  } else {
    const pattern = /^\d*\.?\d+$/g;
    if (!pattern.test(value)) {
      return language.number_invalid;
    }
  }
  if (Number(value) < min) {
    return `${language.value_must_not_be_smaller_than} ${threeDigits(min)}`;
  }
  if (Number(value) > max) {
    return `${language.value_must_not_be_greater_than} ${threeDigits(max)}`;
  }
  return '';
}

export function validateDate({
  date = -1,
  month = -1,
  year = -1,
  min,
  max,
}: {
  date: number;
  month: number;
  year: number;
  min?: number;
  max?: number;
}): string {
  const patternDate = /^([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])$/g;
  const patternMonth = /^([1-9]|0[1-9]|1[0-2])$/g;
  const patternYear = /^\d{4}$/g;
  const monthHas30Date = [4, 6, 9, 11];
  if (!patternDate.test(date?.toString())) {
    return language.date_invalid;
  }
  if (!patternMonth.test(month?.toString())) {
    return language.date_invalid;
  }
  if (!patternYear.test(year?.toString())) {
    return language.date_invalid;
  }
  const dateNumber = Number(date);
  const monthNumber = Number(month);
  const yearNumber = Number(year);
  if (monthNumber === 2 && ((yearNumber % 4 !== 0 && dateNumber > 28) || (yearNumber % 4 === 0 && dateNumber > 29))) {
    return language.date_invalid;
  }
  if (monthHas30Date.includes(monthNumber) && dateNumber > 30) {
    return language.date_invalid;
  }
  const formatter = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale);
  if (
    min &&
    moment(min)
      .startOf('day')
      .isAfter(
        moment()
          .date(dateNumber)
          .month(monthNumber - 1)
          .year(yearNumber)
          .startOf('day'),
      )
  ) {
    return `${language.min_date}: ${formatter.format(moment(min).toDate())}`;
  }
  if (
    max &&
    moment(max)
      .startOf('day')
      .isBefore(
        moment()
          .date(dateNumber)
          .month(monthNumber - 1)
          .year(yearNumber)
          .startOf('day'),
      )
  ) {
    return `${language.max_date}: ${formatter.format(moment(max).toDate())}`;
  }
  return '';
}

export async function validateFileExcel({
  headersSample,
  file,
}: {
  headersSample: Array<{
    key: string;
    type: string;
    require: boolean;
    min: number;
    max: number;
    minLength: number;
    maxLength: number;
    isIn: Array<string>;
    // eslint-disable-next-line no-unused-vars
    validate: (item: any, invalid: Array<string>) => boolean;
    unique: boolean;
  }>;
  file: File;
}): Promise<{
  isInvalid: boolean;
  result?: Array<{
    [key: string]: any;
  }>;
  message?: string[];
}> {
  let isInvalid = false;
  let invalidIndex = '';
  const message = [];
  let result = [];

  const { headers, data } = await readFileExcel(file);

  // check header
  const headerDiff = difference(
    map(headersSample, (header) => header.key),
    headers,
  );
  if (headerDiff.length > 0) {
    message.push(`${language.file_missing_or_wrong_header} ${headerDiff.join(', ')}`);
    isInvalid = true;
  }

  // check duplicate data
  const arrKeyDuplicate = filter(headersSample, (header) => header.unique).map((header) => header.key);
  forEach(arrKeyDuplicate, (key) => {
    if (uniqBy(data, key).length !== data.length) {
      let dataGroupBy = groupBy(data, key);
      dataGroupBy = pickBy(dataGroupBy, (value, dataGroupKey) => value.length > 1 && dataGroupKey !== 'undefined');
      const arrDataDuplicate = Object.keys(dataGroupBy);
      if (arrDataDuplicate.length) {
        message.push(`${language.column} ${key}: ${language.data_duplicate} ${arrDataDuplicate.join(', ')}`);
      }
      isInvalid = true;
    }
  });

  if (message.length > 0) {
    return {
      isInvalid,
      message,
    };
  }

  // check data empty
  if (!data.length) {
    message.push(language.file_not_exist_data);
    isInvalid = true;
    return {
      message,
      isInvalid,
    };
  }

  result = map(data, (item, index) => {
    const invalid: Array<string> = [];
    forEach(headersSample, (header) => {
      const {
        key = '',
        type = 'text',
        require = false,
        min = 0,
        max = Infinity,
        minLength = 0,
        maxLength = 255,
        isIn = [],
        validate,
      } = header;

      // check require data
      if (require && !item[key] && item[key] !== 0) {
        if (key === '#') {
          invalidIndex = language.index_required;
        }
        invalid.push(`${language.column} ${key}: ${language.data_required}`);
      }

      // check isIn data
      if (isIn.length > 0 && item[key] && !isIn.includes(item[key])) {
        invalid.push(`${language.column} ${key}: ${language.is_in} [${isIn.join(', ')}]`);
      }

      // Customize validate by function passed in headersSample
      if (typeof validate === 'function') {
        validate(item, invalid);
      }

      // check type data
      switch (type) {
        case 'number': {
          let validNumber = '';
          validNumber = validateNumber({
            value: item[key] || 0,
            min,
            max,
          });
          if (validNumber) {
            if (key === '#') {
              invalidIndex = `${language.column} ${key}: ${validNumber} (${language.value}: ${item[key]})`;
            }
            invalid.push(`${language.column} ${key}: ${validNumber}`);
          } else {
            const tmp = Number(item[key]);
            if (tmp && String(tmp).length > maxLength) {
              invalid.push(`${language.column} ${key}: ${language.invalid_max_length} (${language.max_length} ${maxLength})`);
            }
            if (tmp && String(tmp).length < minLength) {
              invalid.push(`${language.column} ${key}: ${language.invalid_min_length} (${language.min_length} ${minLength})`);
            }
          }
          break;
        }
        case 'text':
          if (item[key] && item[key].length > maxLength) {
            invalid.push(`${language.column} ${key}: ${language.invalid_max_length} (${language.max_length} ${maxLength})`);
          } else if (validateText(item[key])) {
            invalid.push(`${language.column} ${key}: ${language.data_invalid}`);
          }
          break;
        case 'phone': {
          let validPhone = true;
          if (item[key]) {
            validPhone = validatePhoneInFile(item[key]);
          }
          if (!validPhone) {
            invalid.push(`${language.column} ${key}: ${language.phone_invalid}`);
          }
          break;
        }
        case 'email': {
          let validEmail = false;
          if (item[key]) {
            // eslint-disable-next-line no-param-reassign
            item[key] = item[key] ? item[key].trim() : '';
            validEmail = validateEmail(item[key]);
            if (!validEmail) {
              invalid.push(`${language.column} ${key}: ${language.email_invalid}`);
            } else if (
              item[key].substring(0, item[key].indexOf('@')).length < 4 ||
              item[key].substring(0, item[key].indexOf('@')).length > 30 ||
              item[key].substring(item[key].indexOf('@') + 1, item[key].indexOf('.')).length < 1 ||
              item[key].substring(item[key].indexOf('@') + 1, item[key].indexOf('.')).length > 63
            ) {
              invalid.push(`${language.column} ${key}: ${language.email_limit}`);
            }
          }
          break;
        }
        case 'date': {
          if (item[key]) {
            const tmp = String(item[key]);
            const [date, month, year] = tmp?.split(/\D/) ?? [-1, -1, -1];
            const invalidDate = validateDate({ date: +date, month: +month, year: +year });
            if (invalidDate) {
              invalid.push(`${language.column} ${key}: ${invalidDate} (DD/MM/YYYY)`);
            }
          }
          break;
        }
        case 'date-deactive': {
          if (item[key]) {
            const tmp = String(item[key]);
            const [date, month, year] = tmp?.split(/\D/) ?? [-1, -1, -1];
            const invalidDate = validateDate({ date: +date, month: +month, year: +year });
            if (invalidDate) {
              invalid.push(`${language.column} ${key}: ${invalidDate} (DD/MM/YYYY)`);
            }
            if (item['Trạng thái'] && item['Trạng thái'] === 'Đang hoạt động') {
              invalid.push(`${language.column} ${key}: ${language.date_deactivate_error}`);
            } else if (moment(tmp, 'DD/MM/YYYY').isAfter(moment())) {
              invalid.push(`${language.column} ${key}: ${language.date_validate_deactivate_at}`);
            }
          }
          break;
        }
        default:
          break;
      }
    });

    if (invalid.length) {
      isInvalid = true;
    }
    return {
      ...item,
      index: item['#'],
      message: invalid.join(', '),
      row: index + 1,
    };
  });
  // check data index
  if (invalidIndex) {
    message.push(invalidIndex);
    isInvalid = true;
    return {
      isInvalid,
      message,
    };
  }

  return { isInvalid, result };
}

export const validateImageURL = (url: string): boolean => url?.match(/\.(jpeg|jpg|gif|png)$/) !== null;

export const isNil = ({
  value,
  isCheckStringNull = false,
  isCheckNumberNull = false,
}: {
  value: any;
  isCheckStringNull?: boolean;
  isCheckNumberNull?: boolean;
}) => {
  return value === undefined || value === null || (isCheckStringNull && value === '') || (isCheckNumberNull && value === 0);
};
