import BlockModel, { IBlockModel } from './block';
import FloorModel, { IFloorModel } from './floor';
import RentalLocationModel, { IRentalLocationModel } from './rentalLocation';
import TariffModel, { ITariffModel } from './tariff';

export interface IRentalContractResponse {
  id: number;
  code?: string;
  deposit?: number;
  expiredAt?: Date;
  remindExpiredAt?: Date;
  signingAt?: Date;
  startUsageAt?: Date;
  status?: string;
  createdAt?: Date;
  rentalLocation?: IRentalLocationModel;
  floor?: IFloorModel;
  block?: IBlockModel;
  contractTemplate?: ITariffModel;
}

export interface IRentalContractModel {
  id: number;
  code?: string;
  deposit?: number;
  expiredAt?: Date;
  remindExpiredAt?: Date;
  signingAt?: Date;
  startUsageAt?: Date;
  status?: string;
  createdAt?: Date;
  rentalLocation?: IRentalLocationModel;
  floor?: IFloorModel;
  block?: IBlockModel;
  tariff?: ITariffModel;
}

const RentalContractModel = (data?: IRentalContractResponse): IRentalContractModel => ({
  id: data?.id ?? 0,
  code: data?.code ?? '',
  deposit: data?.deposit ?? 0,
  expiredAt: data?.expiredAt,
  remindExpiredAt: data?.remindExpiredAt,
  signingAt: data?.signingAt,
  startUsageAt: data?.startUsageAt,
  status: data?.status,
  createdAt: data?.createdAt,
  rentalLocation: RentalLocationModel(data?.rentalLocation),
  floor: FloorModel(data?.floor),
  block: BlockModel(data?.block),
  tariff: TariffModel(data?.contractTemplate),
});

export default RentalContractModel;
