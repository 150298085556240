import MemberModel, { IMemberModel } from './member';
import RentalContractModel, { IRentalContractModel } from './rentalContract';
import UnitGroupModel, { IUnitGroupModel } from './unit-group';

export interface IUnitResponse {
  id: number;
  name: string;
  code: string;
  taxCode: string;
  customerIdentifier?: string;
  description?: string;
  localAddress?: string;
  bankAccountId?: string;
  bankAccountName?: string;
  bankAccountOwner?: string;
  unitGroup?: IUnitGroupModel;
  rentalContracts?: IRentalContractModel[];
  resident?: IMemberModel[];
}
export interface IUnitModel {
  id: number;
  name: string;
  code: string;
  taxCode: string;
  customerIdentifier: string;
  description: string;
  localAddress: string;
  bankAccountId: string;
  bankAccountName: string;
  bankAccountOwner: string;
  unitGroup?: IUnitGroupModel;
  rentalContracts?: IRentalContractModel[];
  members?: IMemberModel[];
}

const UnitModel = (data?: IUnitResponse): IUnitModel => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
  taxCode: data?.taxCode ?? '',
  code: data?.code ?? '',
  customerIdentifier: data?.customerIdentifier ?? '',
  description: data?.description ?? '',
  localAddress: data?.localAddress ?? '',
  bankAccountId: data?.bankAccountId ?? '',
  bankAccountName: data?.bankAccountName ?? '',
  bankAccountOwner: data?.bankAccountOwner ?? '',
  unitGroup: UnitGroupModel(data?.unitGroup),
  rentalContracts: data?.rentalContracts?.map((rentalContract) => RentalContractModel(rentalContract)) ?? [],
  members: data?.resident?.map((resident) => MemberModel(resident)) ?? [],
});

export default UnitModel;
