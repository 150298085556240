import { API_TYPE_CALL_API, METHOD, SERVER } from 'shared/constants';
import api from './api';

const FCM_SEND_DEVICE_TOKEN = `${SERVER}/installation`;

export const sendDeviceTokenService = async (token: string, deviceType: string) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FCM_SEND_DEVICE_TOKEN,
      method: METHOD.POST,
      body: {
        deviceToken: token,
        deviceType,
      },
    },
  });
  return response;
};
