import moment from 'moment';
import queryString from 'query-string';
import { isDate, isArray, isObject, isString } from 'lodash';
import { Cookie, handleError, handleResponse } from 'shared/utils';
import {
  METHOD,
  API_TYPE_CALL_API,
  API_TYPE_CALL_API_PARSER,
  API_TYPE_CALL_API_WITH_FILE,
  API_TYPE_DOWNLOAD_FILE,
  OPEN_API,
  INTERNAL_SERVER_ERROR,
  X_UNIT_ID,
  USER_TYPE_EMPLOYEE,
  PARSE_SERVER_APPLICATION_ID,
  PARSE_SERVER_REST_API_KEY,
  USER_TYPE,
  X_USER_TYPE,
  COOKIE_TAG,
  X_UNIT_GROUP_ID,
  USER_TYPE_RESIDENT,
  USER_TYPE_REGENT,
} from 'shared/constants';
import getAccessToken from './accessToken';
import { ObjectAnyType } from 'types/base.type';
import { StatusCodes } from 'http-status-codes';

export interface IHeader {
  'Content-Range'?: string;
  'content-type'?: string;
  Accept?: string;
  Authorization?: string;
  [X_UNIT_GROUP_ID]?: string;
  [X_UNIT_ID]?: string;
  [X_USER_TYPE]?: string;
}

export interface IResponse {
  code: number;
  codeLanguage?: string;
  data?: any;
  error?: {
    statusCode: number;
    message: string | any;
    codeLanguage: string;
    code?: string;
  };
  headers?: Headers;
  totalRecord?: number;
}

export interface IError {
  code: number;
  codeLanguage: string;
  message: string;
  data?: any;
  totalRecord?: number;
}

interface IApiInput {
  apiUrl: string;
  body?: ObjectAnyType;
  method: string;
  params?: ObjectAnyType;
  headers?: ObjectAnyType;
  options?: {
    [key: string]: any;
  };
  accessToken?: string;
}

interface IApiInputWithFile extends IApiInput {
  file: string | Blob;
  name: string;
}

const optionsDefault = {
  bodyType: '',
  userType: '',
  idUnit: 0,
};

const createDefaultHeaders = (isImportFile: boolean = false) => {
  const cacheData = Cookie.get();

  const defaultHeaders: IHeader = {
    'content-type': 'application/json',
    Accept: 'application/json,text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8\n',
    [X_USER_TYPE]: USER_TYPE,
  };

  if (isImportFile) {
    delete defaultHeaders['content-type'];
    delete defaultHeaders.Accept;
  }

  if (cacheData?.type === USER_TYPE_EMPLOYEE || cacheData?.type === USER_TYPE_REGENT) {
    defaultHeaders[X_UNIT_GROUP_ID] = cacheData?.idUnit;
  }

  if (cacheData?.type === USER_TYPE_RESIDENT) {
    defaultHeaders[X_UNIT_GROUP_ID] = cacheData?.idUnitGroup;
    defaultHeaders[X_UNIT_ID] = cacheData?.idUnit;
  }

  return defaultHeaders;
};

const trimData = (value: any, result: any) => {
  if (value === undefined || value === null) {
    return value;
  }
  if (moment.isMoment(value)) {
    return value;
  }
  if (isDate(value)) {
    return value;
  }
  if (isArray(value)) {
    value.forEach((item, index) => {
      if (!result[index]) {
        result[index] = {};
      }
      result[index] = trimData(item, result[index]);
    });
    result = Object.keys(result).map((key) => result[key]);
  } else if (isObject(value)) {
    const newValue = value as ObjectAnyType;
    Object.keys(value).forEach((key: string) => {
      if (!result[key]) {
        result[key] = {};
      }
      result[key] = trimData(newValue[key], result[key]);
    });
  } else if (isString(value)) {
    const regex = new RegExp(/( {2,})/g);
    result = value.trim().replace(regex, ' ');
  } else {
    result = value;
  }
  return result;
};

async function callApi({
  apiUrl,
  body,
  params,
  headers,
  method,
  options = optionsDefault,
  accessToken = '',
}: IApiInput): Promise<IResponse> {
  const option: RequestInit = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      ...createDefaultHeaders(),
      ...headers,
      Authorization: accessToken,
    },
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: method !== METHOD.GET && method !== METHOD.DELETE ? JSON.stringify(trimData(body, {})) : undefined,
  };

  let url = apiUrl;
  const query = queryString.stringify(trimData(params, {}), { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  try {
    const response = await fetch(url, option);
    try {
      let responseParser;
      if (options.bodyType === 'html') {
        responseParser = await response.text();
      } else {
        responseParser = await response.json();
      }
      if (!response.ok) {
        if (response.status < StatusCodes.INTERNAL_SERVER_ERROR) {
          const result = typeof responseParser === 'string' ? JSON.parse(responseParser) : responseParser;
          return {
            code: response.status,
            headers: response.headers,
            error: {
              statusCode: response.status,
              codeLanguage: result.code,
              message: result.message,
            },
          };
        }
        return {
          code: StatusCodes.INTERNAL_SERVER_ERROR,
          headers: response.headers,
          error: {
            message: responseParser,
            statusCode: response.status,
            codeLanguage: INTERNAL_SERVER_ERROR,
          },
        };
      }
      return options.bodyType === 'html'
        ? {
            headers: response.headers,
            code: response.status,
            data: responseParser,
          }
        : {
            headers: response.headers,
            data: responseParser.data,
            code: response.status,
          };
    } catch (err) {
      if (response.status === StatusCodes.NO_CONTENT) {
        return {
          code: StatusCodes.OK,
        };
      }
      return {
        code: StatusCodes.INTERNAL_SERVER_ERROR,
        error: {
          statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
          message: err,
          codeLanguage: INTERNAL_SERVER_ERROR,
        },
      };
    }
  } catch (err) {
    return {
      code: StatusCodes.INTERNAL_SERVER_ERROR,
      error: {
        statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
        message: err,
        codeLanguage: INTERNAL_SERVER_ERROR,
      },
    };
  }
}

async function downloadFile({ apiUrl, body, headers, params, method, accessToken = '' }: IApiInput): Promise<IResponse> {
  const option: RequestInit = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      ...createDefaultHeaders(),
      ...headers,
      Authorization: accessToken,
    },
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: method !== METHOD.GET && method !== METHOD.DELETE ? JSON.stringify(trimData(body as Record<string, any>, {})) : undefined,
  };

  let url = apiUrl;
  const query = queryString.stringify(trimData(params, {}), { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  // create request
  try {
    const response = await fetch(url, option);
    if (response.ok) {
      if (response.status !== StatusCodes.OK) {
        try {
          let result = await response.json();
          if (response.status < StatusCodes.INTERNAL_SERVER_ERROR) {
            result = typeof result === 'string' ? JSON.parse(result) : result;
            return {
              code: response.status,
              headers: response.headers,
              error: {
                message: result.message,
                statusCode: response.status,
                codeLanguage: result.code,
              },
            };
          }
          return {
            code: StatusCodes.INTERNAL_SERVER_ERROR,
            headers: response.headers,
            error: {
              statusCode: response.status,
              codeLanguage: INTERNAL_SERVER_ERROR,
              message: INTERNAL_SERVER_ERROR,
            },
          };
        } catch (e: any) {
          return {
            code: StatusCodes.INTERNAL_SERVER_ERROR,
            headers: response.headers,
            error: {
              statusCode: response.status,
              codeLanguage: INTERNAL_SERVER_ERROR,
              message: e.toString(),
            },
          };
        }
      } else {
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(new Blob([blob]));
        return {
          headers: response.headers,
          code: response.status,
          data: objectUrl,
        };
      }
    } else {
      return {
        code: StatusCodes.INTERNAL_SERVER_ERROR,
        headers: response.headers,
        error: {
          message: 'Can not connect to server',
          statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
          codeLanguage: INTERNAL_SERVER_ERROR,
        },
      };
    }
  } catch (error: any) {
    return {
      code: StatusCodes.INTERNAL_SERVER_ERROR,
      error: {
        message: error.toString(),
        statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
        codeLanguage: 'FAIL',
      },
    };
  }
}

async function callApiWithFile({
  apiUrl,
  file,
  headers,
  method,
  name = 'file',
  options = optionsDefault,
  accessToken = '',
}: IApiInputWithFile): Promise<IResponse> {
  const option: RequestInit = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      ...createDefaultHeaders(true),
      ...headers,
      Authorization: accessToken,
    },
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: undefined,
  };
  const formData = new FormData();
  formData.append(name, file);
  if (options.dataAttach) {
    Object.keys(options.dataAttach).forEach((key) => {
      if (options?.dataAttach[key]) {
        formData.append(key, options.dataAttach[key]);
      }
    });
  }
  if (method !== METHOD.GET && method !== METHOD.DELETE) {
    option.body = formData;
  }
  let url = apiUrl;
  const query = queryString.stringify(options.params as Record<string, any>, { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  try {
    const response = await fetch(url, option);

    try {
      let result = await response.json();
      if (!response.ok) {
        if (response.status < StatusCodes.INTERNAL_SERVER_ERROR) {
          result = typeof result === 'string' ? JSON.parse(result) : result;
          return {
            code: response.status,
            headers: response.headers,
            error: {
              ...result,
              message: result.message,
              statusCode: response.status,
              codeLanguage: result.code,
            },
          };
        }
        return {
          code: response.status,
          headers: response.headers,
          error: {
            ...result,
            message: 'Something went wrong',
            statusCode: response.status,
            codeLanguage: INTERNAL_SERVER_ERROR,
          },
        };
      }
      return {
        headers: response.headers,
        ...result,
        code: response.status,
      };
    } catch (err: any) {
      if (response.status === StatusCodes.NO_CONTENT) {
        return {
          code: StatusCodes.OK,
        };
      }
      return {
        code: StatusCodes.INTERNAL_SERVER_ERROR,
        error: {
          message: err.toString(),
          statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
          codeLanguage: INTERNAL_SERVER_ERROR,
        },
      };
    }
  } catch (err: any) {
    return {
      code: StatusCodes.INTERNAL_SERVER_ERROR,
      error: {
        message: err.toString(),
        statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
        codeLanguage: INTERNAL_SERVER_ERROR,
      },
    };
  }
}

async function callApiParse({ apiUrl, body, headers, method, params, accessToken }: IApiInput): Promise<IResponse> {
  const parserHeaders = {
    'X-Parse-Application-Id': PARSE_SERVER_APPLICATION_ID || '',
    'X-Parse-Rest-Api-Key': PARSE_SERVER_REST_API_KEY || '',
    ...(headers ?? {}),
  };

  return callApi({ apiUrl, body: trimData(body || {}, {}), method, headers: parserHeaders, params, accessToken });
}

const root = async ({ type, payload }: { type: string; payload: IApiInput | IApiInputWithFile }): Promise<IResponse | IError> => {
  try {
    const accessToken = await getAccessToken();
    if (OPEN_API.includes(payload.apiUrl)) {
      if (!accessToken) {
        const err = {
          code: 401,
          message: 'Error',
          codeLanguage: 'Error',
        };
        throw err;
      }
    }
    const newPayload = {
      ...payload,
      accessToken,
    };
    switch (type) {
      case API_TYPE_CALL_API: {
        const response = await callApi(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      case API_TYPE_DOWNLOAD_FILE: {
        const response = await downloadFile(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      case API_TYPE_CALL_API_WITH_FILE: {
        const response = await callApiWithFile(newPayload as IApiInputWithFile);
        return handleResponse({ response, ...newPayload });
      }
      case API_TYPE_CALL_API_PARSER: {
        const response = await callApiParse(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      default: {
        const response = await callApi(newPayload);
        return handleResponse({ response, ...newPayload });
      }
    }
  } catch (err: any) {
    if (err.code === 401 && !['/login', '/register', '/forgot-password'].includes(window.location.pathname)) {
      const cookieCache = Cookie.get(COOKIE_TAG);
      Cookie.save(COOKIE_TAG, {
        ...cookieCache,
        user: undefined,
        idUnit: undefined,
        idUnitGroup: undefined,
        type: undefined,
      });
      window.location.href = '/login';
    }
    return handleError(err);
  }
};

export default root;
