import { convertArrToObj } from 'shared/utils';
import { language } from '../language';

export const DEAL_METHOD_CASH = 'CASH';
export const DEAL_METHOD_TRANSFER = 'TRANSFER';
export const DEAL_METHOD_ONLINE = 'ONLINE';

export const DEAL_METHOD_OPTION = [
  {
    value: DEAL_METHOD_CASH,
    label: language.cash,
  },
  {
    value: DEAL_METHOD_TRANSFER,
    label: language.transfer,
  },
  {
    value: DEAL_METHOD_ONLINE,
    label: language.online,
  },
];

export const DEAL_METHOD_OBJ = convertArrToObj(DEAL_METHOD_OPTION, 'value');
