export interface ITariffResponse {
  name?: string;
  id: number;
}

export interface ITariffModel {
  name?: string;
  id: number;
}

const TariffModel = (data?: ITariffResponse): ITariffModel => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
});

export default TariffModel;
