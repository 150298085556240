import { getToken, onMessage } from 'firebase/messaging';
import { useEffect, useCallback } from 'react';
import { sendDeviceTokenService } from 'services/fcm';
import { messaging } from '../config/firebase';
import { CH_INSTALLATION, FCM_KEY_PAIR } from '../constants';

const getNoticeUrl = (notifyData: { [key: string]: any }): string => {
  const idUnit = notifyData.receiver === 'RESIDENT' ? notifyData.unit : notifyData.unitGroup;
  const notices: {
    [key: string]: string;
  } = {
    ADD_NOTICE: `/application/${idUnit}/announcement`,
  };
  return notices[notifyData.type];
};

export default function useFcm() {
  const sendDeviceTokenToServer = async (currentToken: string) => {
    localStorage.setItem(CH_INSTALLATION, currentToken);
    await sendDeviceTokenService(currentToken, 'browser');
  };

  const handleCallback = () => {
    onMessage(messaging, ({ data }) => {
      const now = new Date();
      const { title, body, type } = data as {
        [key: string]: any;
      };
      const notificationTitle = title;
      const notificationOptions = {
        body,
        icon: '/assets/images/favicon.ico',
        renotify: true,
        tag: type + now.getTime(),
      };
      const notification = new Notification(notificationTitle, notificationOptions);
      notification.onclick = (event) => {
        event.preventDefault();
        if (data) {
          const urlToOpen = new URL(getNoticeUrl(data), window.location.origin).href;
          window.open(urlToOpen);
        }
      };
    });
  };

  const requestPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: FCM_KEY_PAIR });

      await sendDeviceTokenToServer(token);
      handleCallback();
    }
  }, []);

  useEffect(() => {
    requestPermission();
  }, [requestPermission]);

  return messaging;
}
