import { language } from 'shared/language';

export const INTERNAL_SERVER_ERROR = language.internal_server_error;
export const SOMETHING_WRONG = language.something_wrong;
export const USER_LOGIN_FAILED = language.username_or_password_not_right;
export const USER_PHONE_NOT_FOUND = language.phone_invalid;
export const USER_NOT_SUPPORT_OTP = language.otp_code_does_not_support;
export const USER_PHONE_INVALID = language.phone_invalid;
export const SUCCESS = language.success;
export const FAIL = language.system_error;
export const SERVER_ERROR = language.server_error;
export const DATA_INVALID = language.data_invalid;
