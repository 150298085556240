import { useContext } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { IUnitModel } from 'entity/unit';

const useCurrentUnit = () => {
  const { currentUnit, setCurrentUnit } = useContext(GlobalContext);

  const handleChangeCurrentUnit = (unit: IUnitModel) => {
    setCurrentUnit(unit);
  };

  return { currentUnit, handleChangeCurrentUnit };
};

export default useCurrentUnit;
