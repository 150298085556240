import { StatusCodes } from 'http-status-codes';
import { IError } from 'services/api';
import { isObject } from 'lodash';
import { SOMETHING_WRONG } from 'shared/constants';
import { IResponse } from 'services/api';

export const getResponseError = (response: IResponse): IError | null => {
  if (!isObject(response) || (isObject(response) && response && response.error)) {
    return response?.error
      ? {
          code: response.error.statusCode,
          message: response.error.message,
          codeLanguage: response.error?.codeLanguage ?? '',
        }
      : {
          code: StatusCodes.INTERNAL_SERVER_ERROR,
          message: 'Something wrong',
          codeLanguage: SOMETHING_WRONG,
        };
  }

  return null;
};

export const handleError = (error: any): IError => {
  return {
    code: error.code || 500,
    message: error.message || error.toString(),
    codeLanguage: error.codeLanguage || SOMETHING_WRONG,
  };
};

export const handleResponse = ({ response }: { response: IResponse }): IResponse | IError => {
  const err = getResponseError(response);
  if (err) throw err;
  return {
    code: response.code,
    data: response.data,
    totalRecord: +(response?.headers?.get('Content-Range')?.split('/')?.[1] ?? 0),
  };
};
