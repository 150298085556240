export const X_UNIT_GROUP_ID = 'x-unit-group-id';
export const X_UNIT_ID = 'x-unit-id';
export const X_USER_TYPE = 'x-user-type';

export const API_TYPE_CALL_API = 'CALL_API';
export const API_TYPE_DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const API_TYPE_CALL_API_WITH_FILE = 'CALL_API_WITH_FILE';
export const API_TYPE_CALL_API_PARSER = 'CALL_API_PARSER';

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const OPEN_API = ['/login', '/register', '/forgot-password', '/set-new-password', '/check-maintenance'];
