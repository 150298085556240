import moment from 'moment';
import { BILL_TYPE_ADJUST_DOWN, BILL_TYPE_ADJUST_UP } from '../constants/bill';
import { language } from '../language';

type Input = {
  createdFor: number;
  serviceName: string;
  contractTemplateName: string;
  totalNumberUse: number;
  parentId: number;
  registrationPlate: string;
  type: string;
  adjustmentCreatedFor: number;
};

export default function buildDetailBills({
  createdFor,
  serviceName,
  contractTemplateName,
  totalNumberUse,
  parentId,
  registrationPlate,
  type,
  adjustmentCreatedFor,
}: Input) {
  const createdForConverted = moment(createdFor, 'YYYYMM').format('MM/YYYY');
  const adjustmentCreatedForConverted = moment(adjustmentCreatedFor || createdFor, 'YYYYMM').format('MM/YYYY');
  let note = '';
  let content = '';

  if (type === BILL_TYPE_ADJUST_UP) {
    content = `${language.get_more} ${serviceName} ${language.month.toLowerCase()} ${adjustmentCreatedForConverted} ${
      parentId ? `(${language.debt.toLowerCase()})` : ''
    }`;
  } else if (type === BILL_TYPE_ADJUST_DOWN) {
    content = `${language.reduce} ${serviceName} ${language.month.toLowerCase()} ${adjustmentCreatedForConverted}`;
  } else {
    content = `${serviceName}${registrationPlate ? ` (${registrationPlate})` : ''} ${language.month.toLowerCase()} ${createdForConverted} 
     ${parentId ? `(${language.debt.toLowerCase()})` : ''}`;
  }

  note = `${language.fees}: ${contractTemplateName} - ${totalNumberUse}`;

  return { note, content };
}
