export interface IBlockResponse {
  name?: string;
  id: number;
}

export interface IBlockModel {
  name?: string;
  id: number;
}

const BlockModel = (data?: IBlockResponse): IBlockModel => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
});

export default BlockModel;
