import * as BASE from './base';
import * as BUILDING_CONFERENCE from './buildingConference';
import * as VEHICLE_REGISTRATION from './vehicleRegistration';
import * as ACCESS_MANAGEMENT from './accessManagement';
import * as MEMBER from './member';

export const RESPONSE_MAPPING: {
  [key: string]: string;
} = {
  ...BASE,
  ...BUILDING_CONFERENCE,
  ...VEHICLE_REGISTRATION,
  ...ACCESS_MANAGEMENT,
  ...MEMBER,
};

export * from './base';
export * from './buildingConference';
export * from './accessManagement';
