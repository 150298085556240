export interface IRentalLocationResponse {
  name?: string;
  id: number;
}

export interface IRentalLocationModel {
  name?: string;
  id: number;
}

const RentalLocationModel = (data?: IRentalLocationResponse): IRentalLocationModel => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
});

export default RentalLocationModel;
