/* eslint-disable no-unused-vars */
import { useState } from 'react';

export type TUseForm = {
  isShow: boolean;
  data: any;
};

export type TUseFormShowFormHandler = (data?: any) => void;

export type TUseFormHideFormHandler = (isReloadData?: boolean, data?: any) => void;

export default function useForm({ callbackAfterHideForm }: { callbackAfterHideForm?: Function } = {}) {
  const [form, setForm] = useState<TUseForm>({
    isShow: false,
    data: {},
  });

  function showForm(data?: any) {
    setForm({
      isShow: true,
      data,
    });
  }

  function hideForm(isReloadData: boolean, data?: any) {
    setForm({
      isShow: false,
      data: {},
    });
    if (callbackAfterHideForm) {
      callbackAfterHideForm(isReloadData, data);
    }
  }

  return [form, showForm, hideForm] as [TUseForm, TUseFormShowFormHandler, TUseFormHideFormHandler];
}
