import { language } from 'shared/language';

export const VEHICLE_TYPE_INVALID = language.vehicle_type_invalid;
export const LICENSE_PLATE_NOT_VALID = language.license_plate_not_valid;
export const VEHICLE_EXISTED = language.vehicle_existed;
export const VEHICLE_NOT_EXISTED = language.vehicle_not_existed;
export const VEHICLE_REGISTRATION_FORM_NOT_FOUND = language.vehicle_registration_form_not_found;
export const VEHICLE_REGISTRATION_FORM_EXISTED = language.vehicle_registration_form_existed;
export const VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_CHANGE = language.vehicle_registration_form_status_can_not_change;

export const VEHICLE_ERROR_TYPE_INVALID = language.vehicle_error_type_invalid;
export const VEHICLE_ERROR_VEHICLE_EXISTED = language.vehicle_error_vehicle_existed;
export const VEHICLE_NOT_FOUND = language.vehicle_not_found;
export const VEHICLE_ERROR_LICENSE_PLATE_NOT_VALID = language.vehicle_error_license_plate_not_valid;
export const VEHICLE_ERROR_LICENSE_PLATE_EXISTED = language.vehicle_error_license_plate_existed;
export const VEHICLE_ERROR_MISSING_CAVET = language.vehicle_error_missing_cavet;
export const VEHICLE_ERROR_EXISTED = language.vehicle_error_existed;
export const VEHICLE_ERROR_NOT_EXISTED = language.vehicle_error_not_existed;
export const VEHICLE_ERROR_TYPE_NOT_FOUND = language.vehicle_error_type_not_found;
export const VEHICLE_ERROR_STATUS_CAN_NOT_CHANGE = language.vehicle_error_status_can_not_change;
export const VEHICLE_ERROR_REQUIRED_SIT = language.vehicle_error_required_sit;
export const VEHICLE_REGISTRATION_FORM_ERROR_NOT_FOUND = language.vehicle_registration_form_error_not_found;
export const VEHICLE_REGISTRATION_FORM_ERROR_STATUS_CAN_NOT_CHANGE = language.vehicle_registration_form_error_status_can_not_change;
export const VEHICLE_REGISTRATION_FORM_ERROR_EXISTED = language.vehicle_registration_form_error_existed;
export const VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_DELETE = language.vehicle_registration_form_status_can_not_delete;
export const VEHICLE_STATUS_INVALID = language.vehicle_status_invalid;
export const VEHICLE_REGISTRATION_FORM_ERROR_STATUS_CAN_NOT_CANCEL = language.vehicle_registration_form_error_status_can_not_cancel;
export const MISSING_REASON = language.missing_reason;
export const FIELD_CAN_NOT_CHANGE = language.field_can_not_change;
export const VEHICLE_TYPE_CAN_NOT_CHANGE = language.vehicle_type_can_not_change;
export const VEHICLE_UNIT_CAN_NOT_CHANGE = language.vehicle_unit_can_not_change;
export const VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_REJECT = language.vehicle_registration_form_status_can_not_reject;
export const VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_CANCEL = language.vehicle_registration_form_status_can_not_cancel;
