import { Image } from 'antd';
import PropTypes from 'prop-types';
import { language } from 'shared/language';

type Props = {
  code: string;
  description: string;
  src: string;
};

export default function PermissionDenied(props: Props) {
  const { code, description, src } = props;
  return (
    <div
      style={{
        height: '100%',
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '5%',
      }}>
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {src ? (
          <Image
            preview={false}
            width="700"
            height="700"
            src={src}
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="700"
            height="427"
            viewBox="0 0 601 367">
            <defs>
              <path
                id="a"
                d="M600.999 1.452c-.1.069-.193.143-.295.212C570.933 23.008 448.057 39 301.004 39S31.067 23.008 1.297 1.664c-.1-.069-.2-.143-.3-.212C10.488.641 141.172 0 300.997 0"
              />
              <linearGradient
                id="b"
                x1="50%"
                x2="50%"
                y1="-51.6%"
                y2="101%">
                <stop
                  offset="0%"
                  stopColor="#CDD8FF"
                />
                <stop
                  offset="100%"
                  stopColor="#CDD8FF"
                />
              </linearGradient>
              <path
                id="d"
                d="M15.475.02h-.001C7.046.02.214 6.852.214 15.279V191.42h308.812V15.279c0-8.427-6.831-15.259-15.261-15.259H15.475z"
              />
              <linearGradient
                id="e"
                x1="31.064%"
                x2="118.264%"
                y1="41.021%"
                y2="86.12%">
                <stop
                  offset="0%"
                  stopColor="#F2F6FF"
                />
                <stop
                  offset="100%"
                  stopColor="#CDD8FF"
                />
              </linearGradient>
              <path
                id="g"
                d="M27.906.396c-8.452.521-12.313 7.93-13.043 20.659-.731 12.731-12.311 8.662-14.19 20.349-1.879 11.685 5.843 17.414 5.843 17.414s36.52 5.739 45.389-1.144 9.809-19.611 1.25-26.5c-8.559-6.888-3.962-18.783-7.615-24-3.653-5.217-7.826-1.878-7.826-1.878S36.481.36 29.025.361c-.358 0-.732.011-1.119.035"
              />
              <linearGradient
                id="h"
                x1="50%"
                x2="50%"
                y1="81.5%"
                y2="9.2%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="j"
                d="M21.193.179c-2.08.317-4.141.75-6.173 1.3a31.112 31.112 0 0 0-8.019 3.187C1.785 7.896.22 34.404.327 38.367c.107 3.961 10.017 8.87 11.269 9.389a55.545 55.545 0 0 0 15.4 2.3h.069a55.556 55.556 0 0 0 15.4-2.3c1.252-.519 11.169-5.427 11.27-9.389.099-3.964-1.457-30.471-6.674-33.701a31.098 31.098 0 0 0-8.019-3.187 55.759 55.759 0 0 0-6.173-1.3H21.193z"
              />
              <linearGradient
                id="k"
                x1="49.999%"
                x2="49.999%"
                y1="140.6%"
                y2="36.4%">
                <stop
                  offset="0%"
                  stopColor="#801236"
                />
                <stop
                  offset="100%"
                  stopColor="#C62B41"
                />
              </linearGradient>
              <path
                id="m"
                d="M8.199.866C2.187 1.159.592 4.197.592 7.337a8.76 8.76 0 0 0 6.808 8.38l39.364 9.492 1.983-5.217L12.581 1.809A8.797 8.797 0 0 0 8.199.866"
              />
              <linearGradient
                id="n"
                x1="22.069%"
                x2="169.869%"
                y1="46.197%"
                y2="84.302%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="p"
                d="M37.16 1.809L.994 19.992l1.982 5.217 39.365-9.492a8.764 8.764 0 0 0 6.808-8.38c0-3.14-1.596-6.178-7.608-6.471a8.805 8.805 0 0 0-4.381.943"
              />
              <linearGradient
                id="q"
                x1="155.298%"
                x2="-19.898%"
                y1="41.612%"
                y2="54.706%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="s"
                d="M0 367h600.999V.19H0z"
              />
              <path
                id="u"
                d="M1.302 7.522c.95 3.137 4.838 5.292 4.838 5.292l8.611 8.769a32.484 32.484 0 0 1 3.969-4.271 6.569 6.569 0 0 0 .831-8.505c-1.436-1.738-2.457-.982-2.457-.982s.492-4.2-3.399-4.235C9.803 3.557 9.65 2.533 8.668 2.344c-.492-.094-.973.058-1.545.209-.571.151-1.234.302-2.084.207C3.338 2.571 3.489.529 1.902.493L1.879.492C.315.492.356 4.4 1.302 7.522"
              />
              <linearGradient
                id="v"
                x1="73.079%"
                x2="8.864%"
                y1="68.192%"
                y2="-12.509%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="x"
                d="M1.795 44.346c1.21 23.284 3.326 35.379 3.326 35.379h4.648s4.12-26.912 6.088-50.045C17.822 6.548 17.67 1.558 17.67 1.558L.433.424s.152 20.638 1.362 43.922"
              />
              <linearGradient
                id="y"
                x1="50.331%"
                x2="52.406%"
                y1="38.398%"
                y2="140.598%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="A"
                d="M10.13 80.725h4.876s2.91-44.6 4.574-58.209c1.664-13.609 0-21.092 0-21.092L.378.29s4.308 50.2 9.752 80.435"
              />
              <linearGradient
                id="B"
                x1="59.3%"
                x2="38.604%"
                y1="39.195%"
                y2="61.295%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="D"
                d="M6.16 25.832c.605 7.408.376 8.466-1.249 10.054-1.625 1.587.115 4.385 1.778 3.856 1.599-.509 2.843 2.682-2.647 3.177a86.002 86.002 0 0 0 5.268-.518 1.3 1.3 0 0 0 1.14-1.443 1.297 1.297 0 0 0-.518-.893l-.051-.034a1.315 1.315 0 0 1-.614-1.033 336.214 336.214 0 0 1-.387-9.081c-.151-5.367.68-23.4.68-23.4L.26.583s5.294 17.841 5.9 25.249"
              />
              <linearGradient
                id="E"
                x1="68.52%"
                x2="43.622%"
                y1="36.976%"
                y2="53.376%">
                <stop
                  offset="0%"
                  stopColor="#801236"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="G"
                d="M8.582 6.672c-5.24.834-6.492-1.662-6.013-5.2a21.909 21.909 0 0 0-1.547 3.987c-.453 2.872-.529 5.9 5.443 5.9 5.971 0 15.573-2.797 15.573-2.797V.928S15.239 5.615 8.582 6.672"
              />
              <linearGradient
                id="H"
                x1="159.293%"
                x2="27.79%"
                y1="98.399%"
                y2="37.095%">
                <stop
                  offset="0%"
                  stopColor="#801236"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="J"
                d="M.723 5.065l3.742 14.174s8.013 17.123 7.484 21.771c-.529 4.65-5.443 23.021-5.443 23.021h5.443S26.917 40.102 26.01 33.073c-.907-7.03-8.051-29.822-8.051-29.822L4.198.827.723 5.065z"
              />
              <linearGradient
                id="K"
                x1="45.615%"
                x2="64.45%"
                y1="32.712%"
                y2="131.711%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="M"
                d="M.624 16.205c.907 9.827 11.189 20.865 11.491 24.873.302 4.006-6.577 28.953-6.577 28.953h5.443s12.468-22.201 16.02-29.307c3.553-7.106-8.649-25.123-9.33-38.05L2.665.632S-.283 6.378.624 16.205"
              />
              <linearGradient
                id="N"
                x1="38.282%"
                x2="71.733%"
                y1="43.683%"
                y2="68.882%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="P"
                d="M.445 13.139c0 7.218 6.688 13.069 14.94 13.069 8.252 0 14.941-5.851 14.941-13.069 0-7.218-6.689-13.07-14.941-13.07S.445 5.921.445 13.139"
              />
              <linearGradient
                id="Q"
                x1="39.396%"
                x2="77.895%"
                y1="75.711%"
                y2="-17.642%">
                <stop
                  offset="0%"
                  stopColor="#2D122B"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="S"
                d="M12.628 3.71L1.889 23.214a3.554 3.554 0 0 0-1.436 3.7c.236 1.34 6.2 3.932 12.591 6.182-9.4-3.983-7.487-8.488-3.179-15.065C14.174 11.454 21.699.686 21.699.686L12.628 3.71z"
              />
              <linearGradient
                id="T"
                x1="23.366%"
                x2="59.069%"
                y1="-1.144%"
                y2="54.856%">
                <stop
                  offset="0%"
                  stopColor="#801236"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="V"
                d="M.805 13.273S4.32 24.121 7.57 27.523c1.177 1.23 4.136.21 7.432-1.587-.206.078-5.417 2-6.978-2.722C6.431 18.397 4.83.686 4.83.686L.805 13.273z"
              />
              <linearGradient
                id="W"
                x1="13.156%"
                x2="52.393%"
                y1="-17.9%"
                y2="60.2%">
                <stop
                  offset="0%"
                  stopColor="#801236"
                />
                <stop
                  offset="100%"
                  stopColor="#801236"
                />
              </linearGradient>
              <path
                id="Y"
                d="M13.842 10.283c-.73 3.375-2.354 7.162-5.343 9.6-.145.117-.29.231-.44.335a13.46 13.46 0 0 1-.426.299c-.041.028-.082.05-.122.078l-.3.186c-.059.031-.114.063-.173.09l-.245.136c-.072.032-.14.068-.213.1l-.2.1c-.082.032-.164.068-.245.1-.055.023-.109.046-.164.064-.09.036-.181.068-.267.1-.05.018-.1.032-.145.045a3.225 3.225 0 0 1-.277.082c-.009 0-.018 0-.023.009a6.313 6.313 0 0 1-1.2-.472 5.609 5.609 0 0 1-2.948-4.023c-.771-4.309.15-10.936 5.307-14.877a11.568 11.568 0 0 1 3.592-1.9 6.75 6.75 0 0 1 2.232-.308l.027.027a6.115 6.115 0 0 1 .418.531 8.958 8.958 0 0 1 1.405 3.202 8.108 8.108 0 0 1 .127.676c.032.218.059.431.073.639"
              />
              <linearGradient
                id="Z"
                x1="82.785%"
                x2="29.163%"
                y1="130.003%"
                y2="-4.498%">
                <stop
                  offset="0%"
                  stopColor="#B6BFE2"
                />
                <stop
                  offset="100%"
                  stopColor="#B6BFE2"
                />
              </linearGradient>
            </defs>
            <g
              fill="none"
              fillRule="evenodd">
              <g transform="translate(0 327.81)">
                <mask
                  id="c"
                  fill="#fff">
                  <use href="#a" />
                </mask>
                <path
                  fill="url(#b)"
                  d="M600.999 1.452c-.1.069-.193.143-.295.212C570.933 23.008 448.057 39 301.004 39S31.067 23.008 1.297 1.664c-.1-.069-.2-.143-.3-.212C10.488.641 141.172 0 300.997 0s290.518.641 300.002 1.452"
                  mask="url(#c)"
                />
              </g>
              <path
                fill="#CDD9FF"
                d="M600.999 329.954c0 .151-.289.288-.846.438-11.617 2.983-141.331 5.36-299.651 5.36-158.32 0-288.039-2.365-299.656-5.36-.559-.15-.846-.286-.846-.438 0-.15.186-.231.551-.353 9.489-3.036 140.151-5.426 299.952-5.426s290.464 2.39 299.946 5.425c.364.123.55.233.55.354M426.73 253.221a75.755 75.755 0 0 0 2.265 18.433 177.761 177.761 0 0 0 44.378-88.358c-28.243 11.75-46.644 39.336-46.643 69.925M342.688 48.57c-3.536 8.277-13.114 12.121-21.392 8.584-8.279-3.537-12.123-13.115-8.585-21.394 3.534-8.272 13.102-12.117 21.377-8.59 8.284 3.528 12.135 13.102 8.607 21.384-.002.005-.003.01-.007.015zM350.2 5.04L338.933.225l-5.311 10.536a31.781 31.781 0 0 0-11.188-.119L317.35 0l-11.363 4.566 3.69 11.2a31.804 31.804 0 0 0-7.994 7.824l-11.12-3.929-4.808 11.265 10.532 5.311a31.728 31.728 0 0 0-.118 11.186l-10.647 5.088 4.565 11.366 11.202-3.692a31.758 31.758 0 0 0 7.825 7.994L305.182 79.3l11.265 4.808 5.312-10.53c3.692.7 7.48.74 11.185.118l5.085 10.643 11.367-4.566-3.692-11.2a31.79 31.79 0 0 0 7.993-7.825l11.121 3.93 4.808-11.265-10.531-5.312a31.77 31.77 0 0 0 .118-11.185l10.644-5.085-4.566-11.366-11.2 3.692a31.77 31.77 0 0 0-7.825-7.994L350.2 5.04zM262.097 68.04c0 6.542-5.303 11.845-11.845 11.845-6.542 0-11.845-5.303-11.845-11.845 0-6.542 5.303-11.845 11.845-11.845 6.54.002 11.841 5.305 11.84 11.845h.005zm-7.4-31.228h-8.9l-.545 8.551a23.084 23.084 0 0 0-7.508 3.111l-6.433-5.662-6.292 6.292 5.661 6.433a23.104 23.104 0 0 0-3.11 7.508l-8.55.545v8.9l8.55.545a23.104 23.104 0 0 0 3.11 7.508l-5.66 6.433 6.291 6.292 6.433-5.661a23.104 23.104 0 0 0 7.508 3.11l.545 8.551h8.9l.545-8.551a23.104 23.104 0 0 0 7.508-3.11l6.433 5.661 6.292-6.292-5.66-6.433a23.126 23.126 0 0 0 3.11-7.508l8.55-.545v-8.9l-8.55-.545a23.148 23.148 0 0 0-3.11-7.508l5.66-6.433-6.292-6.292-6.433 5.662a23.084 23.084 0 0 0-7.508-3.111l-.545-8.551z"
              />
              <path
                fill="#AEB3FC"
                d="M149.541 124.048h302.158c5.523 0 10 4.478 10 10V328.9H139.541V134.048c0-5.522 4.477-10 10-10"
              />
              <g transform="translate(146 130.81)">
                <mask
                  id="f"
                  fill="#fff">
                  <use href="#d" />
                </mask>
                <path
                  fill="url(#e)"
                  d="M15.475.02h-.001C7.046.02.214 6.852.214 15.279V191.42h308.812V15.279c0-8.427-6.831-15.259-15.261-15.259H15.475z"
                  mask="url(#f)"
                />
              </g>
              <path
                fill="#CDD9FF"
                d="M448.104 130.831H153.137a6.923 6.923 0 0 0-6.924 6.921v9.907h308.813v-9.907c0-3.822-3.1-6.921-6.922-6.921"
              />
              <path
                fill="#FF6382"
                d="M162.9 136.11a3.136 3.136 0 1 1-.001 6.27 3.136 3.136 0 0 1 0-6.27"
              />
              <path
                fill="#FEC40C"
                d="M174.461 136.11a3.136 3.136 0 1 1-.001 6.27 3.136 3.136 0 0 1 .001-6.27"
              />
              <path
                fill="#10C392"
                d="M186.022 136.11a3.136 3.136 0 1 1-.001 6.27 3.136 3.136 0 0 1 .001-6.27"
              />
              <path
                fill="#F3F7FF"
                d="M219.857 135.87h161.525a3.376 3.376 0 1 1 0 6.75H219.857a3.376 3.376 0 0 1 0-6.75M404.566 135.87h34.64a3.376 3.376 0 1 1 0 6.75h-34.64a3.375 3.375 0 1 1 0-6.75"
              />
              <path
                fill="#DBE4FF"
                d="M482.545 67.908a7.134 7.134 0 0 0-6.128-10.806c0-7.892-6.398-14.29-14.29-14.29-7.893 0-14.29 6.398-14.29 14.29a7.135 7.135 0 0 0-6.128 10.806c-9.657.474-16 1.274-16 2.181 0 1.455 16.306 2.634 36.42 2.634 20.115 0 36.423-1.179 36.423-2.634-.003-.907-6.351-1.707-16.007-2.18M557.793 138.047a4.231 4.231 0 0 0-3.634-6.406 8.47 8.47 0 1 0-16.941 0 4.231 4.231 0 0 0-3.633 6.406c-5.725.28-9.486.754-9.486 1.292 0 .862 9.663 1.562 21.59 1.562 11.925 0 21.59-.7 21.59-1.562 0-.538-3.762-1.012-9.486-1.292M66.031 163.027a3.09 3.09 0 0 0-2.652-4.675 6.183 6.183 0 1 0-12.366 0 3.087 3.087 0 0 0-2.652 4.675c-4.178.205-6.924.551-6.924.942 0 .63 7.056 1.14 15.76 1.14 8.703 0 15.758-.51 15.758-1.14 0-.39-2.746-.737-6.924-.942M127.882 92.078a5.001 5.001 0 0 0-4.297-7.578c0-5.534-4.486-10.02-10.02-10.02s-10.02 4.486-10.02 10.02a5.003 5.003 0 0 0-4.298 7.578c-6.772.332-11.221.893-11.221 1.53 0 1.02 11.435 1.846 25.539 1.846 14.105 0 25.54-.827 25.54-1.847 0-.64-4.452-1.2-11.223-1.529"
              />
              <g transform="translate(273 249.81)">
                <mask
                  id="i"
                  fill="#fff">
                  <use href="#g" />
                </mask>
                <path
                  fill="url(#h)"
                  d="M27.906.396c-8.452.521-12.313 7.93-13.043 20.659-.731 12.731-12.311 8.662-14.19 20.349-1.879 11.685 5.843 17.414 5.843 17.414s36.52 5.739 45.389-1.144 9.809-19.611 1.25-26.5c-8.559-6.888-3.962-18.783-7.615-24-3.653-5.217-7.826-1.878-7.826-1.878S36.481.36 29.025.361c-.358 0-.732.011-1.119.035"
                  mask="url(#i)"
                />
              </g>
              <path
                fill="#CDD9FF"
                d="M300.62 343.41c24.853 0 45 2.165 45 4.835s-20.147 4.835-45 4.835-44.999-2.164-44.999-4.835c0-2.67 20.146-4.835 45-4.835"
              />
              <g transform="translate(277 280.81)">
                <mask
                  id="l"
                  fill="#fff">
                  <use href="#j" />
                </mask>
                <path
                  fill="url(#k)"
                  d="M21.193.179c-2.08.317-4.141.75-6.173 1.3a31.112 31.112 0 0 0-8.019 3.187C1.785 7.896.22 34.404.327 38.367c.107 3.961 10.017 8.87 11.269 9.389a55.545 55.545 0 0 0 15.4 2.3h.069a55.556 55.556 0 0 0 15.4-2.3c1.252-.519 11.169-5.427 11.27-9.389.099-3.964-1.457-30.471-6.674-33.701a31.098 31.098 0 0 0-8.019-3.187 55.759 55.759 0 0 0-6.173-1.3H21.193z"
                  mask="url(#l)"
                />
              </g>
              <path
                fill="#FFF"
                d="M304.288 293.186a.949.949 0 1 1-.001 1.897.949.949 0 0 1 .001-1.897M304.288 304.03a.949.949 0 1 1-.001 1.897.949.949 0 0 1 .001-1.897"
              />
              <path
                fill="#2D132C"
                d="M289.67 330.448s1.847 11.27 15.568 8.45c13.72-2.817 10.122-10.223 9.835-10.432-.287-.21-25.402 1.982-25.402 1.982"
              />
              <g transform="translate(265 323.81)">
                <mask
                  id="o"
                  fill="#fff">
                  <use href="#m" />
                </mask>
                <path
                  fill="url(#n)"
                  d="M8.199.866C2.187 1.159.592 4.197.592 7.337a8.76 8.76 0 0 0 6.808 8.38l39.364 9.492 1.983-5.217L12.581 1.809A8.797 8.797 0 0 0 8.199.866"
                  mask="url(#o)"
                />
              </g>
              <path
                fill="#2D132C"
                d="M311.762 349.02l3.313.833s1.415 2.192 6.735 2.743c5.32.55 8.684-1.334 8.684-4.546s-2.586-2.341-6.493-1.878c-2.165.1-4.33-.218-6.373-.94a36.767 36.767 0 0 1-3.881-1.433l-1.985 5.222z"
              />
              <path
                fill="#F9BF9D"
                d="M311.762 349.02l3.313.833s2.559-.331 2.553-4.62a36.767 36.767 0 0 1-3.881-1.434l-1.985 5.222z"
              />
              <path
                fill="#801336"
                d="M316.683 348.783a13.92 13.92 0 0 0 4 1.07c3.97.47 4.6-1.045 2.562-1.61-1.258-.35-3.901-1.006-5.748-1.457a4.618 4.618 0 0 1-.814 1.997"
              />
              <g transform="translate(290 323.81)">
                <mask
                  id="r"
                  fill="#fff">
                  <use href="#p" />
                </mask>
                <path
                  fill="url(#q)"
                  d="M37.16 1.809L.994 19.992l1.982 5.217 39.365-9.492a8.764 8.764 0 0 0 6.808-8.38c0-3.14-1.596-6.178-7.608-6.471a8.805 8.805 0 0 0-4.381.943"
                  mask="url(#r)"
                />
              </g>
              <g transform="translate(0 -.19)">
                <path
                  fill="#2D132C"
                  d="M292.978 349.21l-3.312.833s-1.415 2.192-6.736 2.743c-5.321.55-8.684-1.334-8.684-4.546s2.586-2.341 6.493-1.878c2.165.1 4.329-.218 6.373-.94a36.702 36.702 0 0 0 3.882-1.433l1.984 5.222z"
                />
                <path
                  fill="#F9BF9D"
                  d="M292.978 349.21l-3.312.833s-2.56-.331-2.554-4.62a36.702 36.702 0 0 0 3.882-1.434l1.984 5.222z"
                />
                <path
                  fill="#801336"
                  d="M288.057 348.973a13.92 13.92 0 0 1-4 1.07c-3.969.47-4.595-1.045-2.56-1.61 1.258-.35 3.9-1.006 5.746-1.457.11.72.39 1.405.814 1.997"
                />
                <path
                  fill="#CDD9FF"
                  d="M290.649 311.066h26.766a5.4 5.4 0 0 1 5.4 5.4v18.081H285.25v-18.082a5.4 5.4 0 0 1 5.4-5.399"
                />
                <path
                  fill="#F3F7FF"
                  d="M304.031 319.309a3.499 3.499 0 1 1 0 6.997 3.499 3.499 0 0 1 0-6.997"
                />
                <path
                  fill="#FFF"
                  d="M304.071 281.177v6.5l-1.666 3.707a1.084 1.084 0 0 1-1.778.294l-8.6-9.2a55.94 55.94 0 0 1 6.173-1.3l5.871-.001z"
                />
                <path
                  fill="#FFF"
                  d="M303.997 281.177v6.5l1.665 3.707a1.084 1.084 0 0 0 1.778.294l8.6-9.2a55.988 55.988 0 0 0-6.173-1.3l-5.87-.001z"
                />
                <path
                  fill="#F9BF9D"
                  d="M313.063 271.21s-.47 4.8-3.262 7.907v2.31l-5.733 6.248-5.875-6.5v-2.037s-2.5-1.982-3.198-8.088c0 0-3.106.313-2.743-2.761.363-3.074 2.868-2.248 2.868-2.248s1.306 3.443 6.21 3.5c4.904.057 11.738-7.2 11.738-7.2v3.963a1.91 1.91 0 0 1 3.182.52c1.459 2.301-3.187 4.386-3.187 4.386"
                />
                <path
                  fill="#F19381"
                  d="M309.8 279.117v2.31l-5.732 6.248-5.874-6.5v-2.037a7.048 7.048 0 0 0 5.478 2.7 9.666 9.666 0 0 0 6.129-2.72"
                />
                <path
                  fill="#2D132C"
                  d="M304.031 247.1c-7.826 0-14.189 6.608-14.189 14.737 0 .465.022.921.064 1.377a14.927 14.927 0 0 0 3.776 8.7c.424.47.88.91 1.362 1.32v-2.596a12.21 12.21 0 0 1-1.362-1.8 12.994 12.994 0 0 1-1.957-5.8c-.036-.4-.054-.804-.054-1.21-.253-6.828 5.075-12.567 11.902-12.82 6.826-.253 12.565 5.076 12.818 11.902.012.306.012.611 0 .917 0 .408-.017.812-.054 1.211a13.025 13.025 0 0 1-1.862 5.64 12.347 12.347 0 0 1-1.36 1.847v2.612c.482-.417.937-.867 1.36-1.344a14.966 14.966 0 0 0 3.686-8.622c.041-.442.058-.893.058-1.344 0-8.119-6.362-14.728-14.188-14.728"
                />
                <mask
                  id="t"
                  fill="#fff">
                  <use href="#s" />
                </mask>
                <path
                  fill="#FFF"
                  d="M293.762 274.475h1.352v-10.972h-1.352z"
                  mask="url(#t)"
                />
                <path
                  fill="#C72C41"
                  d="M293.63 263.501h.132v10.976h-.131a5.488 5.488 0 1 1 0-10.976"
                  mask="url(#t)"
                />
                <path
                  fill="#FFF"
                  d="M313.044 274.475h1.353v-10.972h-1.353z"
                  mask="url(#t)"
                />
                <path
                  fill="#C72C41"
                  d="M314.526 274.477h-.132V263.5h.132a5.488 5.488 0 0 1 0 10.976M333.144 272.401a2.394 2.394 0 0 1 4.553 1.435 2.391 2.391 0 0 1-4.553-1.435m7.3-8.6c.366-1.162-.422-2.218-1.963-2.7a4.211 4.211 0 0 0-3.991.672l-2.339-2.589c1.892-1.54 4.512-2.072 7.712-1.062 3.557 1.121 5.522 3.487 4.64 6.285-1.2 3.794-5.313 2.965-6.104 5.48l-3.582-1.13c1.046-3.319 4.976-2.892 5.627-4.957"
                  mask="url(#t)"
                />
                <path
                  fill="#0DA073"
                  d="M519.696 197.145s25.371 35.841-3.132 84.873c-6.639 6.23-49.489 22.713-56.709 29.514l-3.185-44.835s5.71-57.558 63.026-69.552"
                  mask="url(#t)"
                />
                <path
                  fill="#B0FFE7"
                  d="M489.238 281.732l1.08.69c.228-.354 22.786-35.973 23.736-70.124l-1.282-.035c-.94 33.79-23.308 69.115-23.534 69.47"
                  mask="url(#t)"
                />
                <path
                  fill="#10C392"
                  d="M553.46 269.697s-6.332 43.454-60.782 59.319c-9.104-.067-18.622-.126-28.556-.18l-4.27-52.214s43.822-37.752 93.608-6.925"
                  mask="url(#t)"
                />
                <path
                  fill="#0DA073"
                  d="M462.234 305.738c7.374-.473 55.962-4.379 77.152-26.19l-.92-.894c-20.899 21.51-69.165 25.35-76.336 25.8l.104 1.284zM141.453 259.865s-34.564-15.818-40.677 11.244c-6.113 27.06-34.09 7.885-47.84 27.263s-10.752 31.782-32.353 31.782H139.76v-70.29"
                  mask="url(#t)"
                />
                <path
                  fill="#B0FFE7"
                  d="M64.101 330.153S3.151 312.68 6.145 271.108c2.994-41.572 56.033-11.422 57.956 59.045"
                  mask="url(#t)"
                />
                <path
                  fill="#10C392"
                  d="M64.101 330.153c-.009-.327-.026-.646-.037-.972-43.755-45.799-45.4-69.894-45.409-70.133l-1.282.058c.044.99 1.586 24.628 45.476 70.66.803.256 1.252.387 1.252.387"
                  mask="url(#t)"
                />
                <path
                  fill="#FEC40C"
                  d="M77.589 321.374l1.768-1.787c-.08-.078-7.984-7.979-16.271-22.07-7.636-12.985-18.608-33.927-20.664-60.488l-1.07.083a144.815 144.815 0 0 0 19.613 61.757c8.45 14.347 16.295 22.178 16.624 22.505"
                  mask="url(#t)"
                />
                <path
                  fill="#FEC40C"
                  d="M42.423 237.029s5.89-4.2-1.07-13.757c0 0-5.61 8.072 0 13.84l1.07-.083zM42.618 247.513s7.138 1.192 8.973-10.484c0 0-8.86 2.438-8.973 10.484M42.618 247.513s-7.138 1.192-8.972-10.484c0 0 8.861 2.438 8.972 10.484M45.412 258.822s7.233.248 7.524-11.568c0 0-8.466 3.576-7.524 11.568M45.412 258.822s-6.92 2.115-10.267-9.22c0 0 9.1 1.257 10.267 9.22M47.8 268.88s7.233.248 7.524-11.568c0 0-8.466 3.576-7.524 11.568M47.8 268.88s-6.92 2.115-10.266-9.22c0 0 9.1 1.257 10.266 9.22M52.277 279.65s7.149-1.12 5.208-12.779c0 0-7.64 5.106-5.208 12.778M52.277 279.65s-6.4 3.382-11.821-7.12c0 0 9.177-.482 11.82 7.12M57.272 290.063s7.124-1.272 4.933-12.887c0 0-7.529 5.27-4.933 12.887"
                  mask="url(#t)"
                />
                <path
                  fill="#FEC40C"
                  d="M57.272 290.063s-6.323 3.52-11.971-6.863c0 0 9.165-.678 11.971 6.863M64.101 302.782s7.038-1.686 4.175-13.153c0 0-7.209 5.7-4.175 13.153M64.101 302.782s-6.108 3.881-12.35-6.156c0 0 9.11-1.21 12.35 6.156"
                  mask="url(#t)"
                />
                <path
                  fill="#10C392"
                  d="M83.267 329.267s-21.008-17.135-7.19-33.971c13.816-16.836-26.626-32.044-13.795-49.224 12.831-17.18-12.83-45.571 7.378-46.932 20.21-1.363 26.278 13.545 17.31 28.546-8.97 15 23.292 24.024 16.304 51.11-6.988 27.086-23.722 27.986-20.007 50.47"
                  mask="url(#t)"
                />
                <path
                  fill="#B0FFE7"
                  d="M82.748 329.644c-.09-.125-8.97-12.7-.057-30.951 8.87-18.158 4.227-36.386-3.84-45.638-8.478-9.724-6.565-33.115-2.039-40.484l1.093.673c-4.25 6.92-6.077 29.799 1.913 38.967 8.336 9.56 13.152 28.362 4.025 47.044-8.56 17.527-.142 29.518-.056 29.636l-1.039.753z"
                  mask="url(#t)"
                />
              </g>
              <g transform="translate(461 156.81)">
                <mask
                  id="w"
                  fill="#fff">
                  <use href="#u" />
                </mask>
                <path
                  fill="url(#v)"
                  d="M1.302 7.522c.95 3.137 4.838 5.292 4.838 5.292l8.611 8.769a32.484 32.484 0 0 1 3.969-4.271 6.569 6.569 0 0 0 .831-8.505c-1.436-1.738-2.457-.982-2.457-.982s.492-4.2-3.399-4.235C9.803 3.557 9.65 2.533 8.668 2.344c-.492-.094-.973.058-1.545.209-.571.151-1.234.302-2.084.207C3.338 2.571 3.489.529 1.902.493L1.879.492C.315.492.356 4.4 1.302 7.522"
                  mask="url(#w)"
                />
              </g>
              <g>
                <path
                  fill="#F9BF9D"
                  d="M467.568 184.006v-2.627s-2.74.076-2.854-2.3c-.114-2.376.983-13.457.983-13.457 1.78.398 3.583.676 5.4.832 2.834.227 3.665-.718 4.12-.3.454.418-.3 4.649.793 5.707 0 0 1.361-1.436 2.231.114a2.5 2.5 0 0 1-1.693 3.477s-.15 2.653-.424 4.677a40.614 40.614 0 0 0-.284 4.395 2.298 2.298 0 0 1-1.606 3.212c-2.646.832-7.636-.491-7.636-.491l.97-3.24z"
                />
                <path
                  fill="#F19381"
                  d="M470.743 168.99l-5.588 2.56c-.18 2.12-.35 4.366-.418 5.933l4.034-3.626s3.101-.43 3.39-2.37a2.214 2.214 0 0 0-1.418-2.498M469.127 187.181l3.657-7.03a9.14 9.14 0 0 1-5.216 1.228v2.627l-.607 2.036 2.166 1.14z"
                />
              </g>
              <g transform="translate(454 241.81)">
                <mask
                  id="z"
                  fill="#fff">
                  <use href="#x" />
                </mask>
                <path
                  fill="url(#y)"
                  d="M1.795 44.346c1.21 23.284 3.326 35.379 3.326 35.379h4.648s4.12-26.912 6.088-50.045C17.822 6.548 17.67 1.558 17.67 1.558L.433.424s.152 20.638 1.362 43.922"
                  mask="url(#z)"
                />
              </g>
              <g transform="translate(466 240.81)">
                <mask
                  id="C"
                  fill="#fff">
                  <use href="#A" />
                </mask>
                <path
                  fill="url(#B)"
                  d="M10.13 80.725h4.876s2.91-44.6 4.574-58.209c1.664-13.609 0-21.092 0-21.092L.378.29s4.308 50.2 9.752 80.435"
                  mask="url(#C)"
                />
              </g>
              <g>
                <path
                  fill="#2D132C"
                  d="M459.121 321.535v1.929l-11.487 5.44h16.14v-7.37zM476.134 321.535v1.929l-11.264 5.442h16.14v-7.371z"
                />
                <path
                  fill="#F19381"
                  d="M487.072 232.086c-.283.47-.743.807-1.276.936-.85.17-.913 2.636.522 2.72 1.435.086 2.71-1.473 2.71-1.473l-.767-2.466-1.189.283z"
                />
                <path
                  fill="#F9BF9D"
                  d="M453.393 173.641a8.13 8.13 0 0 1-1.737 2.221c-.89.642-1.645.51-1.986.831a4.2 4.2 0 0 0-.53 1.607l-4.606-1.21 1.227-3.42 6.672-1.683.96 1.654zM447.976 166.374s1.65.823 2.318.511c.668-.312 1.485.822 2.306.652.822-.17.228-1.148-.681-1.58-.908-.432-.958-.216-2.151-.339-1.192-.123-1.823.133-1.792.756"
                />
                <path
                  fill="#C72C41"
                  d="M492.798 215.93c.739-3.175-6.237-7.6-6.237-7.6s-.83 18.03-.68 23.4c.113 4 .3 7.538.39 9.08.023.424.252.81.612 1.035a1.3 1.3 0 0 1-.57 2.372c-5.846.685-11.73.991-17.614.916-13.208 0-17.008-1.252-14.682-4.6.615-6.167.906-12.362.87-18.56.074-12.17.15-22.6.15-22.6s-9.598 2.8-15.571 2.8c-5.972 0-5.9-3.026-5.442-5.9.457-2.874 9.847-20.315 9.847-20.315l5.9 1.755s-3.52 11.113-3.46 12.36c.058 1.247 6.464-1.588 9.692-3.289.422-.222.8-.43 1.152-.63.209-.123.414-.236.608-.345 1.634-.91 3.013-1.528 6.723-1.732.217-.01.444-.02.68-.028.34-.013.7-.027 1.079-.03.218-.006.446-.01.68-.01.209-.005.422-.005.644-.005 0 0 .513 2.495 3.856 2.613 3.343.118 4.427-2.1 4.427-2.1s.644.181 1.66.5l.658.218a45.778 45.778 0 0 1 8.9 3.82c4.14 2.553 13.249 16.1 15.14 21.485 1.893 5.386-9.524 25.12-9.524 25.12l-5.616-3.575s4.989-12.982 5.728-16.155"
                />
              </g>
              <g transform="translate(477 201.81)">
                <mask
                  id="F"
                  fill="#fff">
                  <use href="#D" />
                </mask>
                <path
                  fill="url(#E)"
                  d="M6.16 25.832c.605 7.408.376 8.466-1.249 10.054-1.625 1.587.115 4.385 1.778 3.856 1.599-.509 2.843 2.682-2.647 3.177a86.002 86.002 0 0 0 5.268-.518 1.3 1.3 0 0 0 1.14-1.443 1.297 1.297 0 0 0-.518-.893l-.051-.034a1.315 1.315 0 0 1-.614-1.033 336.214 336.214 0 0 1-.387-9.081c-.151-5.367.68-23.4.68-23.4L.26.583s5.294 17.841 5.9 25.249"
                  mask="url(#F)"
                />
              </g>
              <path
                fill="#F9BF9D"
                d="M487.726 232.503a10.73 10.73 0 0 1-1.724 2.756c-1.085 1.193-3.437 2.752-2.7 3.915.738 1.163 3.772.907 5.84-.2a7.529 7.529 0 0 0 3-3.663l-4.416-2.808z"
              />
              <g transform="translate(433 190.81)">
                <mask
                  id="I"
                  fill="#fff">
                  <use href="#G" />
                </mask>
                <path
                  fill="url(#H)"
                  d="M8.582 6.672c-5.24.834-6.492-1.662-6.013-5.2a21.909 21.909 0 0 0-1.547 3.987c-.453 2.872-.529 5.9 5.443 5.9 5.971 0 15.573-2.797 15.573-2.797V.928S15.239 5.615 8.582 6.672"
                  mask="url(#I)"
                />
              </g>
              <g>
                <path
                  fill="#801336"
                  d="M467.158 184.007h-.237c-.236 0-.462 0-.68.009-.38 0-.739.018-1.079.03l-.408.018.865 3.032a3.3 3.3 0 0 1-1.04.68 4.167 4.167 0 0 1-4.586-1.288 5.499 5.499 0 0 1-.885-1.364c-.209.092-.413.188-.613.29.254.56.591 1.078 1 1.537a5.215 5.215 0 0 0 3.768 1.765 4.087 4.087 0 0 0 1.575-.31 4.01 4.01 0 0 0 .987-.592l.137.48.363-.106c.241-.085.421-.726.551-1.494a7.448 7.448 0 0 0 6.167 3.409 6.49 6.49 0 0 0 1.842-.258c1.674-.488 2.69-2.645 3.283-4.613l-.644-.213c-.537 1.814-1.428 3.765-2.829 4.175a6.583 6.583 0 0 1-6.908-2.392 7.766 7.766 0 0 1-.589-.881 6.179 6.179 0 0 0 .392-1.831l-.023-.084h-.409z"
                />
                <path
                  fill="#2C3449"
                  d="M456.475 174.689h-1.228l.595 1.69h1.268l.594-1.69z"
                />
                <path
                  fill="#414E6E"
                  d="M446.812 165.956l9.969 1.494a2.764 2.764 0 0 1 1.86 1.1c.316.444.486.978.48 1.524a3.333 3.333 0 0 1-.409 1.592 2.83 2.83 0 0 1-2.046 1.426l-9.854 1.6v-8.736z"
                />
                <path
                  fill="#B7C0E2"
                  d="M446.812 165.956a4.367 4.367 0 1 1 0 8.734 4.367 4.367 0 0 1 0-8.734"
                />
                <path
                  fill="#414E6E"
                  d="M446.812 167.005a3.318 3.318 0 1 1 0 6.636 3.318 3.318 0 0 1 0-6.636"
                />
                <path
                  fill="#2C3449"
                  d="M446.812 167.366a2.956 2.956 0 1 1 0 5.913 2.956 2.956 0 0 1 0-5.913"
                />
                <path
                  stroke="#FFF"
                  strokeLinecap="round"
                  d="M449.14 170.322a2.33 2.33 0 0 1-2.328 2.33"
                />
                <path
                  fill="#FFF"
                  d="M445.397 168.217a.807.807 0 1 1-.002 1.614.807.807 0 0 1 .002-1.614"
                />
                <path
                  fill="#353F5A"
                  d="M464.394 173.668h-1.228l.595 1.69h1.268l.594-1.69zM467.545 167.942h1.852a2.505 2.505 0 0 1 2.315 3.49c-.012.028-.023.056-.037.083a2.464 2.464 0 0 1-2.243 1.379h-3.81l1.923-4.952z"
                />
                <path
                  fill="#414E6E"
                  d="M456.248 165.617l10.959 1.643a3.046 3.046 0 0 1 2.046 1.208c.349.49.533 1.076.527 1.676a3.654 3.654 0 0 1-.449 1.75 3.108 3.108 0 0 1-2.251 1.567l-10.832 1.757v-9.6z"
                />
                <path
                  fill="#B7C0E2"
                  d="M456.248 165.617a4.8 4.8 0 1 1 0 9.6 4.8 4.8 0 0 1 0-9.6"
                />
                <path
                  fill="#414E6E"
                  d="M456.249 166.77a3.648 3.648 0 1 1 0 7.295 3.648 3.648 0 0 1 0-7.295"
                />
                <path
                  fill="#2C3449"
                  d="M456.249 167.167a3.251 3.251 0 1 1 0 6.502 3.251 3.251 0 0 1 0-6.502"
                />
                <path
                  stroke="#FFF"
                  strokeLinecap="round"
                  d="M458.809 170.418a2.56 2.56 0 0 1-2.561 2.56"
                />
                <path
                  fill="#FFF"
                  d="M454.692 168.102a.886.886 0 1 1 0 1.772.886.886 0 0 1 0-1.772"
                />
                <path
                  fill="#E07A00"
                  d="M464.214 191.116a4.103 4.103 0 0 1-1.574.308 5.222 5.222 0 0 1-3.77-1.764 8.66 8.66 0 0 1-1.718-3.506 38.672 38.672 0 0 1-1.017-9.784l.682.018c-.064 3.166.253 6.33.942 9.421a8.206 8.206 0 0 0 1.61 3.388 4.17 4.17 0 0 0 4.586 1.29c1.533-.627 2.408-2.278 2.345-4.41l-.06-2.059c.219-.005.446-.009.68-.009l.06 2.045c.073 2.431-.961 4.323-2.766 5.062"
                />
                <path
                  fill="#F79C1D"
                  d="M478.166 185.229c-.313 1.9-1.338 6.573-3.906 7.32a6.48 6.48 0 0 1-1.841.259 7.445 7.445 0 0 1-5.792-2.88 11.704 11.704 0 0 1-2.146-5.855 43.68 43.68 0 0 1-.249-2.445c-.182-2.1-.191-4.26-.177-6.273h.68c-.013 2 0 4.137.173 6.21.068.825.14 1.655.254 2.476.16 1.968.852 3.855 2 5.46a6.58 6.58 0 0 0 6.908 2.392c2.222-.65 3.161-5.176 3.438-6.886l.658.222z"
                />
                <path
                  fill="#2D132C"
                  d="M117.981 321.84v1.625l10.7 5.442h-16.143v-7.067z"
                />
                <path
                  fill="#2D132C"
                  d="M132.95 321.84v1.625l10.7 5.442h-16.14v-7.067z"
                />
              </g>
              <g transform="translate(121 257.81)">
                <mask
                  id="L"
                  fill="#fff">
                  <use href="#J" />
                </mask>
                <path
                  fill="url(#K)"
                  d="M.723 5.065l3.742 14.174s8.013 17.123 7.484 21.771c-.529 4.65-5.443 23.021-5.443 23.021h5.443S26.917 40.102 26.01 33.073c-.907-7.03-8.051-29.822-8.051-29.822L4.198.827.723 5.065z"
                  mask="url(#L)"
                />
              </g>
              <g transform="translate(107 251.81)">
                <mask
                  id="O"
                  fill="#fff">
                  <use href="#M" />
                </mask>
                <path
                  fill="url(#N)"
                  d="M.624 16.205c.907 9.827 11.189 20.865 11.491 24.873.302 4.006-6.577 28.953-6.577 28.953h5.443s12.468-22.201 16.02-29.307c3.553-7.106-8.649-25.123-9.33-38.05L2.665.632S-.283 6.378.624 16.205"
                  mask="url(#O)"
                />
              </g>
              <g transform="translate(135 185.81)">
                <mask
                  id="R"
                  fill="#fff">
                  <use href="#P" />
                </mask>
                <path
                  fill="url(#Q)"
                  d="M.445 13.139c0 7.218 6.688 13.069 14.94 13.069 8.252 0 14.941-5.851 14.941-13.069 0-7.218-6.689-13.07-14.941-13.07S.445 5.921.445 13.139"
                  mask="url(#R)"
                />
              </g>
              <g>
                <path
                  fill="#F9BF9D"
                  d="M140.358 212.717l3.667-6.766s-2.23-.945-1.247-3.78c.983-2.835 4.913-1.247 4.573 1.663a4.08 4.08 0 0 0 2.91-2.8c.568-2.268 1.059-2.57 1.588-2.23.529.34 1.928 4.536 4.347 4.952 2.419.416 3.06-.15 3.1.68.039.831-3.78 8.2-5.707 10.13a4.181 4.181 0 0 1-4.007 1.285l-1.635 3.307-6.548 1.475s-4.376-5.459-1.041-7.916"
                />
                <path
                  fill="#F19381"
                  d="M144.573 212.717l.724 7.041 2.65-.6 1.634-3.307s-4.016-.734-5.008-3.134"
                />
                <path
                  fill="#C72C41"
                  d="M109.89 250.024a3.554 3.554 0 0 0-1.437 3.7c.453 2.57 22 9.752 27.14 10.28 4.111.424 5.272-.604 5.263-1.38a9.328 9.328 0 0 1 .593-2.953l6.352-19.592s3.515 10.848 6.766 14.25c3.251 3.402 20.108-10.357 20.108-10.357l-1.432-5.84s-11.113 6.142-12.927 6.218c-1.814.076-2.57-10.66-2.8-15.422-.23-4.763-.454-6.123-1.588-7.333-1.134-1.21-7.075-4.278-7.075-4.278a4.863 4.863 0 0 1-5.247 1.368c-3.25-1.1-3.25-5.972-3.25-5.972s-12.4.019-16.48 0c-4.08-.02-20.714 13.267-24.569 17.576-3.855 4.309-10.584 17.312-10.584 17.312l3.856 3.023s11.34-12.171 15.5-15.12c4.161-2.947 12.55-4.988 12.55-4.988l-10.74 19.508z"
                />
                <path
                  fill="#F9BF9D"
                  d="M176.919 237.388s1.162-.027 1.3 2.1a4.287 4.287 0 0 1-3.175 4.423c-1.9.51-3.2.113-2.467-2.382a5.253 5.253 0 0 1 3.359-3.6c.369-.093.708-.279.983-.54M88.726 247.605s-7.975 8.58-7.333 10.734c.642 2.154 6.691-2.721 7.333-2.91.642-.19.756 1.625 1.928.68s.227-4.31 1.928-5.481l-3.856-3.023z"
                />
              </g>
              <g transform="translate(108 226.81)">
                <mask
                  id="U"
                  fill="#fff">
                  <use href="#S" />
                </mask>
                <path
                  fill="url(#T)"
                  d="M12.628 3.71L1.889 23.214a3.554 3.554 0 0 0-1.436 3.7c.236 1.34 6.2 3.932 12.591 6.182-9.4-3.983-7.487-8.488-3.179-15.065C14.174 11.454 21.699.686 21.699.686L12.628 3.71z"
                  mask="url(#U)"
                />
              </g>
              <g transform="translate(147 226.81)">
                <mask
                  id="X"
                  fill="#fff">
                  <use href="#V" />
                </mask>
                <path
                  fill="url(#W)"
                  d="M.805 13.273S4.32 24.121 7.57 27.523c1.177 1.23 4.136.21 7.432-1.587-.206.078-5.417 2-6.978-2.722C6.431 18.397 4.83.686 4.83.686L.805 13.273z"
                  mask="url(#X)"
                />
              </g>
              <g>
                <g transform="translate(178 207.81)">
                  <mask
                    id="aa"
                    fill="#fff">
                    <use href="#Y" />
                  </mask>
                  <path
                    fill="url(#Z)"
                    d="M13.842 10.283c-.73 3.375-2.354 7.162-5.343 9.6-.145.117-.29.231-.44.335a13.46 13.46 0 0 1-.426.299c-.041.028-.082.05-.122.078l-.3.186c-.059.031-.114.063-.173.09l-.245.136c-.072.032-.14.068-.213.1l-.2.1c-.082.032-.164.068-.245.1-.055.023-.109.046-.164.064-.09.036-.181.068-.267.1-.05.018-.1.032-.145.045a3.225 3.225 0 0 1-.277.082c-.009 0-.018 0-.023.009a6.313 6.313 0 0 1-1.2-.472 5.609 5.609 0 0 1-2.948-4.023c-.771-4.309.15-10.936 5.307-14.877a11.568 11.568 0 0 1 3.592-1.9 6.75 6.75 0 0 1 2.232-.308l.027.027a6.115 6.115 0 0 1 .418.531 8.958 8.958 0 0 1 1.405 3.202 8.108 8.108 0 0 1 .127.676c.032.218.059.431.073.639a19.08 19.08 0 0 1-.45 5.181"
                    mask="url(#aa)"
                  />
                </g>
                <path
                  fill="#B7C0E2"
                  d="M175.667 234.407l2.935-6.067 1.719.785-2.932 6.067z"
                />
                <path
                  fill="#414E6E"
                  d="M170.172 244.505l4.62-9.553 2.708 1.235-4.621 9.553z"
                />
                <path
                  fill="#2C3449"
                  d="M172.714 247.394l-3.326-1.517a.828.828 0 0 1 .688-1.507l3.325 1.518a.828.828 0 1 1-.687 1.506M177.696 236.464l-3.326-1.518a.828.828 0 1 1 .688-1.506l3.325 1.517a.827.827 0 1 1-.687 1.507"
                />
                <path
                  fill="#8F9BC1"
                  d="M178.54 228.47l-2.6 5.372 4.208-4.363-.842-.817-.5-.226z"
                />
                <path
                  fill="#B7C0E2"
                  d="M190.237 207.832a6.754 6.754 0 0 0-2.232.308 11.547 11.547 0 0 0-3.592 1.9c-5.157 3.941-6.078 10.568-5.307 14.877a5.611 5.611 0 0 0 2.948 4.023c.383.198.785.356 1.2.472a5.94 5.94 0 0 1-7.588-4.862c-.771-4.649.145-11.793 5.307-16.048 1.8-1.479 4.427-2.857 6.817-2.227.6.158 1.162.436 1.65.817.289.221.556.469.797.74"
                />
                <path
                  fill="#8F9BC1"
                  d="M191.842 218.093c-.73 3.375-2.354 7.162-5.343 9.6-.145.117-.29.231-.44.335a13.46 13.46 0 0 1-.426.299c-.041.028-.082.05-.122.078l-.3.186c-.06.031-.114.063-.173.09l-.245.136c-.072.032-.14.068-.213.1l-.2.1c-.082.032-.164.068-.245.1-.055.023-.11.046-.164.064-.09.036-.181.068-.267.1-.05.018-.1.032-.145.045a3.225 3.225 0 0 1-.277.082c-.01 0-.018 0-.023.01a6.313 6.313 0 0 1-1.2-.473 9.614 9.614 0 0 0 1.955-1.242c4.763-3.883 6.055-11.2 5.792-14.792a9.014 9.014 0 0 0-1.8-4.767 6.754 6.754 0 0 1 2.232-.308l.027.027a6.115 6.115 0 0 1 .418.531 8.958 8.958 0 0 1 1.405 3.202 8.108 8.108 0 0 1 .127.676c.032.218.059.431.073.64a19.082 19.082 0 0 1-.446 5.181"
                />
              </g>
              <text
                fill="#AEB3FC"
                fontFamily="Montserrat-Bold, Montserrat"
                fontSize="72"
                fontWeight="bold"
                letterSpacing=".71"
                transform="translate(0 -1)">
                <tspan
                  x="249"
                  y="229.734">
                  {code || '403'}
                </tspan>
              </text>
            </g>
          </svg>
        )}
      </div>
      <div
        style={{
          textAlign: 'center',
          marginTop: '15px',
        }}>
        <p style={{ fontSize: '25px' }}>{description || language.permission_denied}</p>
      </div>
    </div>
  );
}

PermissionDenied.propTypes = {
  code: PropTypes.string,
  src: PropTypes.string,
  description: PropTypes.string,
};

PermissionDenied.defaultProps = {
  code: undefined,
  src: undefined,
  description: undefined,
};
