import * as XLSX from 'xlsx';

export default function readFileExcel(file: File): Promise<{
  headers: Array<string>;
  data: Array<{
    [key: string]: any;
  }>;
}> {
  const reader = new FileReader();
  reader.readAsBinaryString(file);

  function getHeaderRow(sheet: { [key: string]: any }): Array<string> {
    const headers: Array<string> = [];

    if (!sheet['!ref']) {
      return headers;
    }

    const range = XLSX.utils.decode_range(sheet['!ref']);
    let C;
    const R = range.s.r;
    /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; C += 1) {
      const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
      /* find the cell in the first row */

      // var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
      let hdr = ''; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      if (hdr) {
        headers.push(hdr);
      }
    }
    return headers;
  }

  return new Promise((resolve) => {
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const xlRowObject: Array<{}> = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      const headers = getHeaderRow(workbook.Sheets[workbook.SheetNames[0]]);
      resolve({
        headers,
        data: xlRowObject,
      });
    };
  });
}
