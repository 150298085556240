import { convertArrToObj } from 'shared/utils';
import { language } from 'shared/language';

export const HELP_DESK_STATUS_NEW = 'NEW';
export const HELP_DESK_STATUS_APPROVED = 'APPROVED';
export const HELP_DESK_STATUS_COMPLETED = 'COMPLETED';
export const HELP_DESK_STATUS_RATED = 'RATED';

export const HELP_DESK_STATUS = [
  {
    key: HELP_DESK_STATUS_NEW,
    label: language.waiting_approve,
  },
  {
    key: HELP_DESK_STATUS_APPROVED,
    label: language.processing,
  },
  {
    key: HELP_DESK_STATUS_COMPLETED,
    label: language.complete,
  },
  {
    key: HELP_DESK_STATUS_RATED,
    label: language.rated,
  },
];

export const HELP_DESK_STATUS_OBJ: {
  [key: string]: {
    key: string;
    label: string;
    color: string;
    icon: string;
  };
} = {
  [HELP_DESK_STATUS_NEW]: {
    key: HELP_DESK_STATUS_NEW,
    label: language.waiting_approve,
    color: '#f50',
    icon: 'minus-circle',
  },
  [HELP_DESK_STATUS_APPROVED]: {
    key: HELP_DESK_STATUS_APPROVED,
    label: language.processing,
    color: '#0530AD',
    icon: 'sync-alt',
  },
  [HELP_DESK_STATUS_COMPLETED]: {
    key: HELP_DESK_STATUS_COMPLETED,
    label: language.complete,
    color: '#87d068',
    icon: 'check',
  },
  [HELP_DESK_STATUS_RATED]: {
    key: HELP_DESK_STATUS_RATED,
    label: language.rated,
    color: '#87d068',
    icon: 'star',
  },
};

export const HELP_DESK_MESSAGE_TYPE_NOTICE_HELP_DESK_EST_COMPLETE_TIME = 'NOTICE_HELPDESK_EST_COMPLETE_TIME';
export const HELP_DESK_MESSAGE_TYPE_NOTICE_HELP_DESK_COMPLETED = 'NOTICE_HELPDESK_COMPLETED';
export const HELP_DESK_MESSAGE_TYPE_NOTICE_HELP_DESK_RATED = 'HELPDESK_RATED';

export const REQUEST_PROCESS_STATUS_APPROVED = 'APPROVED';
export const REQUEST_PROCESS_STATUS_REJECTED = 'REJECTED';
export const REQUEST_PROCESS_STATUS_NOT_STARTED = 'NOT_STARTED';
export const REQUEST_PROCESS_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const REQUEST_PROCESS_STATUS_ON_HOLD = 'ON_HOLD';
export const REQUEST_PROCESS_STATUS_COMPLETED = 'COMPLETED';
export const REQUEST_PROCESS_STATUS_CANCELLED = 'CANCELLED';
export const REQUEST_PROCESS_STATUS = [
  {
    value: REQUEST_PROCESS_STATUS_NOT_STARTED,
    label: language.not_start,
    color: 'default',
  },
  {
    value: REQUEST_PROCESS_STATUS_IN_PROGRESS,
    label: language.in_progress,
    color: 'processing',
  },
  {
    value: REQUEST_PROCESS_STATUS_ON_HOLD,
    label: language.on_hold,
    color: 'warning',
  },
  {
    value: REQUEST_PROCESS_STATUS_COMPLETED,
    label: language.completed,
    color: 'success',
  },
  {
    value: REQUEST_PROCESS_STATUS_CANCELLED,
    label: language.canceled,
    color: 'error',
  },
];
export const REQUEST_PROCESS_STATUS_OBJ = convertArrToObj(REQUEST_PROCESS_STATUS, 'value');
