import { language } from 'shared/language';
import { convertArrToObj } from 'shared/utils';

export const MEMBER_DEFAULT_FILTER = {
  name: undefined,
  phone: undefined,
  email: undefined,
  role: undefined,
};

export const HOUSEHOLDER = 'HOUSEHOLDER';
export const ACCOUNTANT = 'ACCOUNTANT';
export const RENTER = 'RENTER';
export const MEMBER = 'MEMBER';
export const EMPOWER = 'EMPOWER';

export const MEMBER_ROLES = [
  { id: HOUSEHOLDER, label: language.admin },
  { id: ACCOUNTANT, label: language.accountant },
  // { id: RENTER, label: language.label.renter },
  { id: MEMBER, label: language.member },
  { id: EMPOWER, label: language.empower },
];

export const MEMBER_ROLE_OBJ = convertArrToObj(MEMBER_ROLES, 'id');
