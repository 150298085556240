import { useContext } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { IUserModel } from 'entity/user';

const useCurrentUser = () => {
  const { currentUser, setCurrentUser } = useContext(GlobalContext);

  const handleChangeCurrentUser = (user: IUserModel) => {
    setCurrentUser(user);
  };

  return { currentUser, handleChangeCurrentUser };
};

export default useCurrentUser;
