import { useContext } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { NOT_NEED_PERMISSION } from 'shared/constants';

function hasAnyAuthority(authorities: string[] = [], hasAnyAuthorities: string[] = []) {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some((auth) => [...authorities, NOT_NEED_PERMISSION].includes(auth));
  }
  return false;
}

const usePermission = () => {
  const { authorities, setAuthorities } = useContext(GlobalContext);

  const getPermission = () => {
    setAuthorities([]);
  };

  function checkViewPermission(hasAnyAuthorities: string[]) {
    return hasAnyAuthority(authorities, hasAnyAuthorities);
  }

  return { checkViewPermission, getPermission, authorities };
};

export default usePermission;
