import React, { Dispatch, RefObject, SetStateAction, useState } from 'react';
import PropTypes from 'prop-types';
import UserModel, { IUserModel } from 'entity/user';
import UnitModel, { IUnitModel } from 'entity/unit';

type TBadges = {
  helpDesk: number;
  announcement: number;
};

type TGlobalContextValue = {
  currentUser: IUserModel;
  setCurrentUser: Dispatch<SetStateAction<IUserModel>>;
  currentUnit: IUnitModel;
  setCurrentUnit: Dispatch<SetStateAction<IUnitModel>>;
  authorities: string[];
  setAuthorities: Dispatch<SetStateAction<string[]>>;
  visibleFilterBox: boolean;
  setVisibleFilterBox: Dispatch<SetStateAction<boolean>>;
  titlePage: string;
  setTitlePage: Dispatch<SetStateAction<string>>;
  visibleSearchBox: boolean;
  setVisibleSearchBox: Dispatch<SetStateAction<boolean>>;
  noticeSearchBox: number;
  setNoticeSearchBox: Dispatch<SetStateAction<number>>;
  layoutScrollRef: RefObject<HTMLElement> | undefined;
  units: Array<IUnitModel>;
  setUnits: Dispatch<SetStateAction<Array<IUnitModel>>>;
  badges: TBadges;
  setBadges: Dispatch<SetStateAction<TBadges>>;
};

const Context = React.createContext<TGlobalContextValue>({
  currentUser: UserModel(),
  setCurrentUser: () => {},
  currentUnit: UnitModel(),
  setCurrentUnit: () => {},
  authorities: [],
  setAuthorities: () => {},
  visibleFilterBox: false,
  setVisibleFilterBox: () => {},
  titlePage: '',
  setTitlePage: () => {},
  visibleSearchBox: false,
  setVisibleSearchBox: () => {},
  noticeSearchBox: 0,
  setNoticeSearchBox: () => {},
  layoutScrollRef: React.createRef<HTMLDivElement>(),
  units: [],
  setUnits: () => {},
  badges: {
    helpDesk: 0,
    announcement: 0,
  },
  setBadges: () => {},
});

const layoutScrollRef = React.createRef<HTMLElement>();

type Props = {
  children?: React.ReactNode;
};

const GlobalContext = (props: Props) => {
  const { children } = props;
  const [units, setUnits] = useState<Array<IUnitModel>>([]);
  const [currentUnit, setCurrentUnit] = useState<IUnitModel>(UnitModel());
  const [currentUser, setCurrentUser] = useState<IUserModel>(UserModel());
  const [authorities, setAuthorities] = useState<string[]>([]);
  const [titlePage, setTitlePage] = useState<string>('');
  const [visibleFilterBox, setVisibleFilterBox] = useState<boolean>(false);
  const [visibleSearchBox, setVisibleSearchBox] = useState<boolean>(false);
  const [noticeSearchBox, setNoticeSearchBox] = useState<number>(0);
  const [badges, setBadges] = useState<TBadges>({
    helpDesk: 0,
    announcement: 0,
  });

  const ctx: TGlobalContextValue = {
    units,
    setUnits,
    currentUser,
    setCurrentUser,
    currentUnit,
    setCurrentUnit,
    authorities,
    setAuthorities,
    visibleFilterBox,
    setVisibleFilterBox,
    titlePage,
    setTitlePage,
    visibleSearchBox,
    setVisibleSearchBox,
    noticeSearchBox,
    setNoticeSearchBox,
    layoutScrollRef,
    badges,
    setBadges,
  };
  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export { Context };

GlobalContext.propTypes = {
  children: PropTypes.node,
};

GlobalContext.defaultProps = {
  children: <div />,
};

export default GlobalContext;
