export interface IFloorResponse {
  name?: string;
  id: number;
}

export interface IFloorModel {
  name?: string;
  id: number;
}

const FloorModel = (data?: IFloorResponse): IFloorModel => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
});

export default FloorModel;
