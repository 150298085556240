import moment, { Moment } from 'moment';

export interface IUserResponse {
  id: number;
  avatar: string;
  birthday: string;
  email: string;
  fullName: string;
  gender: string;
  phone: string;
  type: string;
  employees: Array<any>;
  residents: Array<any>;
}

export interface IUserModel {
  id: number;
  avatar: string;
  birthday: Moment | undefined;
  email: string;
  fullName: string;
  gender: string;
  phone: string;
  type: string;
  residents: Array<any>;
  employees: Array<any>;
}

const UserModel = (data?: IUserResponse): IUserModel => ({
  id: data?.id ?? 0,
  avatar: data?.avatar ?? '',
  birthday: data?.birthday ? moment(data?.birthday) : undefined,
  email: data?.email ?? '',
  fullName: data?.fullName ?? '',
  gender: data?.gender ?? '',
  phone: data?.phone ?? '',
  type: data?.type ?? '',
  residents: data?.residents ?? [],
  employees: data?.employees ?? [],
});

export default UserModel;
