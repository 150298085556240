import { API_TYPE_CALL_API, METHOD, SERVER } from 'shared/constants';
import api from './api';

const COUNTRY_CODE = `${SERVER}/base/country-codes`;
export const CITY = `${SERVER}/base/cities`;
export const DISTRICT = `${SERVER}/base/districts`;
export const CHECK_MAINTENANCE = `${SERVER}/base/check-maintenance`;

export const getCountryCodesService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: COUNTRY_CODE,
      method: METHOD.GET,
    },
  });
  return response;
};

export const getCitiesService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY,
      method: METHOD.GET,
    },
  });
  return response;
};

export const getDistrictsService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT,
      method: METHOD.GET,
    },
  });
  return response;
};

export const checkMaintenance = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CHECK_MAINTENANCE,
      method: METHOD.GET,
    },
  });
  return response;
};
