import { initializeApp } from 'firebase/app';
import { initializeAnalytics } from 'firebase/analytics';
import { getMessaging, deleteToken, Messaging } from 'firebase/messaging';
import { CH_INSTALLATION, ENV, FCM_API_KEY, FCM_APP_ID, FCM_PROJECT_ID, FCM_SENDER_ID } from '../constants';

const firebaseConfig = {
  messagingSenderId: FCM_SENDER_ID,
  apiKey: FCM_API_KEY,
  projectId: FCM_PROJECT_ID,
  appId: FCM_APP_ID,
};

const app = initializeApp(firebaseConfig);

const messaging: Messaging = getMessaging(app);

const deleteDeviceToken = () => {
  const deviceToken = localStorage.getItem(CH_INSTALLATION);
  if (deviceToken && messaging) {
    deleteToken(messaging);
    localStorage.removeItem(CH_INSTALLATION);
  }
  return deviceToken;
};

initializeAnalytics(app, {
  config: { debug_mode: ENV !== 'PRODUCTION' },
});

export { messaging, deleteDeviceToken };
