import { useContext } from 'react';
import { Context } from 'globalContext';

const useBadge = () => {
  const { badges, setBadges } = useContext(Context);

  const getBadges = async () => {
    const [announcementBadge, helpDeskBadge] = await Promise.all([0, 0]);

    setBadges({
      announcement: announcementBadge,
      helpDesk: helpDeskBadge,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const getHelpDeskBadges = async (idUnit: number) => {
    // if (badges.helpDesk === 0) {
    //   const result = await countHelpDeskBadgeService(idUnit);
    //   setBadges({
    //     ...badges,
    //     helpDesk: result?.data?.badge ?? 0,
    //   });
    // }
  };

  return { badges, getBadges, getHelpDeskBadges };
};

export default useBadge;
