import { language } from 'shared/language';

export const VISITOR_EXPECTED_INCOMING_AT_INVALID = language.visitor_expected_incoming_at_invalid;
export const VISITOR_EXPECTED_LEAVING_AT_INVALID = language.visitor_expected_leaving_at_invalid;
export const VISITOR_EMAIL_OR_PHONE_IS_REQUIRED = language.visitor_email_or_phone_is_required;
export const VISITOR_INACTIVE = language.access_management_inactive;
export const UNIT_NOT_IN_UNIT_GROUP = language.unit_not_in_unit_group;
export const GUEST_TYPE_INVALID = language.guest_type_invalid;
export const CUSTOMER_IDENTIFIER_INVALID = language.customer_identifier_invalid;
export const UNIT_OR_CUSTOMER_IDENTIFIER_NOT_FOUND = language.unit_or_customer_identifier_not_found;
